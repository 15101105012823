import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationCrearPermisoArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearPermisoGQL extends Mutation<
  TypeMutation,
  MutationCrearPermisoArgs
> {
  document = gql`
    mutation crearPermiso($data: CrearPermisoInput!) {
      crearPermiso(data: $data) {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
      }
    }
  `;
}
