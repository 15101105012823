import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationCrearEmpresaGeneradoraArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearEmpresaGeneradoraGQL extends Mutation<
  TypeMutation,
  MutationCrearEmpresaGeneradoraArgs
> {
  document = gql`
    mutation crearEmpresaGeneradora($data: CrearEmpresaGeneradoraInput!) {
      crearEmpresaGeneradora(data: $data) {
        id
        notifica
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            piso
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        marcasNeumatico {
          id
          nombre
        }
      }
    }
  `;
}
