import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import {
  Query as TypeQuery,
  QueryStockNfUsDestruidosEnCatArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class StockNFUsDestruidosEnCatGQL extends Query<
  TypeQuery,
  QueryStockNfUsDestruidosEnCatArgs
> {
  document = gql`
    query stockNFUsDestruidosEnCat($filtros: StockNFUsDestruidosEnCatInput) {
      stockNFUsDestruidosEnCat(filtros: $filtros) {
        idMunicipio
        municipio
        idCat
        cat
        kgsDestruidos
      }
    }
  `;
}
