import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation, MutationBorrarUserArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarUserGQL extends Mutation<
  TypeMutation,
  MutationBorrarUserArgs
> {
  document = gql`
    mutation borrarUser($id: ID!) {
      borrarUser(id: $id) {
        id
        name
        username
        superAdmin
        email
        roles {
          id
          nombre
          descripcion
        }
        permisos {
          id
          nombre
          descripcion
        }
        cats {
          id
          persona {
            id
            nombre
            domicilio {
              id
              direccion
            }
          }
          municipio {
            id
            persona {
              id
              nombre
            }
          }
        }
        persona {
          id
          nombre
          apellido
          telefono
          email
          nroDocumento
          firma
          tipoDocumento {
            id
            nombre
          }

          domicilio {
            id
            direccion
            numero
            piso
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        accessTokens {
          id
          fechaAccesso
        }
      }
    }
  `;
}
