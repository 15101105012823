import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { QueryStockNfUsParaEgresoArgs, Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class StockNFUsParaEgresoGQL extends Query<
  TypeQuery,
  QueryStockNfUsParaEgresoArgs
> {
  document = gql`
    query stockNFUsParaEgreso($filtros: StockNFUsEgresoInput!) {
      stockNFUsParaEgreso(filtros: $filtros)
    }
  `;
}
