import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor() {}

  showSuccess(message: string): void {
    Swal.fire({
      position: "top-end",
      text: message,
      type: "success",
      showConfirmButton: false,
      timer: 1500,
    });
  }

  showError(message: string): void {
    Swal.fire({
      text: message,
      type: "error",
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
}
