import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import {
  Query as TypeQuery,
  QueryGestionesPorExpedienteArgs,
  QueryPersonaArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class GestionesPorExpedienteGQL extends Query<
  TypeQuery,
  QueryGestionesPorExpedienteArgs
> {
  document = gql`
    query gestionesPorExpediente($expediente_id: ID!) {
      gestionesPorExpediente(expediente_id: $expediente_id) {
        id
        descripcion
        fechaCreacion
        tipoGestion {
          id
          descripcion
        }
        autor {
          id
          username
        }
      }
    }
  `;
}
