import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation, MutationIngresarArgs } from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class IngresarGQL extends Mutation<TypeMutation, MutationIngresarArgs> {
  document = gql`
    mutation ingresar($data: LoginInput!) {
      ingresar(data: $data) {
        token_type
        access_token
        expires_in
        refresh_token
        user {
          id
          name
          username
          superAdmin
          email
          persona {
            id
            nombre
            apellido
          }
          roles {
            id
            nombre
            permisos {
              id
              nombre
              descripcion
            }
          }
          permisos {
            id
            nombre
            descripcion
          }
        }
      }
    }
  `;
}
