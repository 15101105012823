import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationBorrarPermisoArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarPermisoGQL extends Mutation<
  TypeMutation,
  MutationBorrarPermisoArgs
> {
  document = gql`
    mutation borrarPermiso($id: ID!) {
      borrarPermiso(id: $id) {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
      }
    }
  `;
}
