import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { Operador, Persona } from "src/app/graphql/Graphql";
import { PersonasGQL } from "src/app/graphql/queries/personasGQL";

@Injectable({
  providedIn: "root",
})
export class PersonasService {
  constructor(private personasGQL: PersonasGQL) {}

  getPersonas(): Observable<Persona[]> {
    return this.personasGQL
      .watch({
        filtros: {
          O: [
            {
              campo: "tipo_persona_id",
              operador: Operador.Igual,
              valor: 1,
            },
            {
              campo: "tipo_persona_id",
              operador: Operador.Igual,
              valor: 2,
            },
          ],
        },
      })
      .valueChanges.pipe(map(({ data }) => data?.personas));
  }
}
