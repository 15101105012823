import { Injectable } from "@angular/core";
import { Subscription, gql } from "apollo-angular";
import { Subscription as TypeSubscription } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class NuevoIngresoNFUsGQL extends Subscription<TypeSubscription> {
  document = gql`
    subscription nuevoIngresoNFUs {
      nuevoIngresoNFUs {
        id
        fecha
        ubicacionFisica
        total
        tipoComprobante {
          id
          nombre
        }
        cat {
          id
          persona {
            id
            nombre
          }
          municipio {
            id
            persona {
              id
              nombre
            }
          }
        }
        detallesComprobante {
          id
          cantidad
          tipoNeumatico {
            id
            nombre
          }
        }
      }
    }
  `;
}
