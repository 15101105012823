import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import {
  Query as TypeQuery,
  QueryStockNfUsParaDestruccionArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class StockNFUsParaDestruccionGQL extends Query<
  TypeQuery,
  QueryStockNfUsParaDestruccionArgs
> {
  document = gql`
    query stockNFUsParaDestruccion($filtros: StockNFUsDestruccionInput!) {
      stockNFUsParaDestruccion(filtros: $filtros)
    }
  `;
}
