import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  MutationIngresarNfUsArgs,
  Mutation as TypeMutation,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class IngresarNFUsGQL extends Mutation<
  TypeMutation,
  MutationIngresarNfUsArgs
> {
  document = gql`
    mutation ingresarNFUs($idCat: ID!, $data: IngresarNFUsInput!) {
      ingresarNFUs(idCat: $idCat, data: $data) {
        id
        fecha
        tipoComprobante {
          id
          nombre
        }
      }
    }
  `;
}
