import { Injectable, OnDestroy } from "@angular/core";
import { Cat, Filtros, Operador, Comprobante } from "src/app/graphql/Graphql";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { CatsGQL } from "src/app/graphql/queries/catsGQL";
import { ComprobantesGQL } from "src/app/graphql/queries/comprobantesGQL";
import { AutoUnsub } from "src/app/decorators/AutoUnsubDecorator";

@Injectable({
  providedIn: "root",
})
@AutoUnsub()
export class CatsService implements OnDestroy {
  constructor(
    private catsGQL: CatsGQL,
    private comprobantesGQL: ComprobantesGQL
  ) {}

  getCats(): Observable<Cat[]> {
    return this.catsGQL.watch().valueChanges.pipe(
      map(({ data }) => {
        return data?.cats;
      })
    );
  }

  getComprobantes(idCat: string = "0"): Observable<Comprobante[]> {
    let filtroManifiesto: Filtros = {
      campo: "tipo_comprobante_id",
      operador: Operador.MenorIgual,
      valor: "5",
    };

    let filtros: Filtros =
      idCat == "0"
        ? filtroManifiesto
        : {
            Y: [
              filtroManifiesto,
              {
                campo: "cat_id",
                operador: Operador.Igual,
                valor: idCat,
              },
            ],
          };

    return this.comprobantesGQL
      .watch({ filtros: filtros })
      .valueChanges.pipe(map(({ data }) => data?.comprobantes));
  }

  ngOnDestroy(): void {}
}
