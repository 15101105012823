import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation } from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class SalirGQL extends Mutation<TypeMutation> {
  document = gql`
    mutation salir {
      salir {
        status
        message
      }
    }
  `;
}
