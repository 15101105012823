import { Injectable } from "@angular/core";
import { SlackService } from "src/app/services/eventos/slack.service";

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  constructor(private slackService: SlackService) {}

  logError(error: Error, title: string) {
    this.slackService.postErrorOnSlack(error, title);
  }
}
