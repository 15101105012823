import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class UsuarioActualGQL extends Query<TypeQuery> {
  document = gql`
    query CurrentUser {
      yo {
        id
        name
        username
        superAdmin
        email
        persona {
          id
          nombre
          apellido
        }
        roles {
          id
          nombre
          permisos {
            id
            nombre
            descripcion
          }
        }
        permisos {
          id
          nombre
          descripcion
        }
        accessTokens {
          id
          fechaAccesso
        }
      }
    }
  `;
}
