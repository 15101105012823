export { ActualizarCatGQL } from "./mutaciones/actualizarCatGQL";
export { CrearCatGQL } from "./mutaciones/crearCatGQL";
export { CrearEmpresaGeneradoraGQL } from "./mutaciones/crearEmpresaGeneradoraGQL";
export { CrearEmpresaGQL } from "./mutaciones/crearEmpresaGQL";
export { CrearItemGQL } from "./mutaciones/crearItemGQL";
export { CrearPermisoGQL } from "./mutaciones/crearPermisoGQL";
export { CrearPersonaGQL } from "./mutaciones/crearPersonaGQL";
export { CrearRolGQL } from "./mutaciones/crearRolGQL";
export { CrearUserGQL } from "./mutaciones/crearUserGQL";
export { EgresarNFUsGQL } from "./mutaciones/egresarNFUsGQL";
export { EliminarCatGQL } from "./mutaciones/eliminarCatGQL";
export { EliminarEmpresaGeneradoraGQL } from "./mutaciones/eliminarEmpresaGeneradoraGQL";
export { EliminarItemGQL } from "./mutaciones/eliminarItemGQL";
export { EliminarPermisoGQL } from "./mutaciones/eliminarPermisoGQL";
export { EliminarPersonaGQL } from "./mutaciones/eliminarPersonaGQL";
export { EliminarRolGQL } from "./mutaciones/eliminarRolGQL";
export { EliminarUserGQL } from "./mutaciones/eliminarUserGQL";
export { IngresarGQL } from "./mutaciones/ingresarGQL";
export { IngresarNFUsGQL } from "./mutaciones/ingresarNFUsGQL";
export { LogoutGQL } from "./mutaciones/logoutGQL";
export { RefreshTokenGQL } from "./mutaciones/refrescarTokenGQL";
export { SalirGQL } from "./mutaciones/salirGQL";
export { MoverEstadoExpedienteGQL } from "./mutaciones/moverEstadoExpedienteGQL";
export { ActualizarUserGQL } from "./mutaciones/actualizarUserGQL";
export { ActualizarRolGQL } from "./mutaciones/actualizarRolGQL";
export { ActualizarPersonaGQL } from "./mutaciones/actualizarPersonaGQL";
export { ActualizarItemGQL } from "./mutaciones/actualizarItemGQL";
export { ActualizarEmpresaGeneradoraGQL } from "./mutaciones/actualizarEmpresaGeneradoraGQL";
export { EliminarExpedienteGQL } from "./mutaciones/eliminarExpedienteGQL";
export { GeneroExpedienteTipoNFUsGQL } from "./mutaciones/generoExpedienteTipoNFUsGQL";
export { CambiarFechaNotificacionExpedienteGQL } from "./mutaciones/cambiarFechaNotificacionExpedienteGQL";
export { CrearGestionExpedienteGQL } from "./mutaciones/crearGestionExpedienteGQL";
export { EliminarComprobanteGQL } from "./mutaciones/eliminarComprobanteGQL";
export { DestruirNFUsGQL } from "./mutaciones/destruirNFUsGQL";
