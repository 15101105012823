import { Injectable } from "@angular/core";
import { Subscription, gql } from "apollo-angular";
import { Subscription as TypeSubscription } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class PersonaActualizadaGQL extends Subscription<TypeSubscription> {
  document = gql`
    subscription personaActualizada {
      personaActualizada {
        id
        nroDocumento
        apellido
        nombre
        email
        telefono
      }
    }
  `;
}
