import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { QueryEmpresasGeneradorasArgs, Query as TipeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EmpresasGeneradorasGQL extends Query<
  TipeQuery,
  QueryEmpresasGeneradorasArgs
> {
  document = gql`
    query empresasGeneradoras($filtros: Filtros) {
      empresasGeneradoras(filtros: $filtros) {
        id
        notifica
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            piso
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        marcasNeumatico {
          id
          nombre
        }
      }
    }
  `;
}
