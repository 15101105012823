import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationActualizarItemArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class ActualizarItemGQL extends Mutation<
  TypeMutation,
  MutationActualizarItemArgs
> {
  document = gql`
    mutation actualizarItem($data: ActualizarItemInput!) {
      actualizarItem(data: $data) {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
        link
        itemPadre {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        itemHijos {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        permisos {
          id
          nombre
          descripcion
        }
      }
    }
  `;
}
