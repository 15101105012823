import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationRefrescarTokenArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class RefreshTokenGQL extends Mutation<
  TypeMutation,
  MutationRefrescarTokenArgs
> {
  document = gql`
    mutation refrescarToken($data: RefreshTokenInput) {
      refrescarToken(data: $data) {
        token_type
        access_token
        expires_in
        refresh_token
      }
    }
  `;
}
