export { CatsGQL } from "./queries/catsGQL";
export { CiudadesGQL } from "./queries/ciudadesGQL";
export { ComprobantesGQL } from "./queries/comprobantesGQL";
export { EmpresaGQL } from "./queries/empresaGQL";
export { EmpresasGeneradorasGQL } from "./queries/empresasGeneradorasGQL";
export { EstadosExpedientesGQL } from "./queries/estadosExpedientesGQL";
export { EstadosPosibleDeEstadoActualGQL } from "./queries/estadosPosibleDeEstadoActualGQL";
export { ExpedientesNFUsGQL } from "./queries/expedientesNFUsGQL";
export { ItemsGQL } from "./queries/itemsGQL";
export { MarcasNeumaticoGQL } from "./queries/marcasNeumaticoGQL";
export { MovimientosExpedienteGQL } from "./queries/movimientosExpedienteGQL";
export { MisPermisosGQL } from "./queries/misPermisosGQL";
export { PermisosGQL } from "./queries/permisosGQL";
export { PersonaGQL } from "./queries/personaGQL";
export { PersonasGQL } from "./queries/personasGQL";
export { ProvinciasGQL } from "./queries/provinciasGQL";
export { RolesGQL } from "./queries/rolesGQL";
export { StockNFUsGQL } from "./queries/stockNFUsGQL";
export { StockNFUsParaEgresoGQL } from "./queries/stockNFUsParaEgresoGQL";
export { StockNFUsParaDestruccionGQL } from "./queries/stockNFUsParaDestruccionGQL";
export { TiposDocumentoGQL } from "./queries/tiposDocumentoGQL";
export { TiposNeumaticoGQL } from "./queries/tiposNeumaticoGQL";
export { TiposLocalidadGQL } from "./queries/tiposLocalidadGQL";
export { UsersGQL } from "./queries/usersGQL";
export { UsuarioActualGQL } from "./queries/usuarioActualGQL";
export { RecaudacionMensualPorMunicipioGQL } from "./queries/recaudacionMensualPorMunicipioGQL";
export { CantidadNFUsEnCatHistoricoGQL } from "./queries/cantidadNFUsEnCatHistoricoGQL";
export { CantidadNFUsPorTipoYMunicipioGQL } from "./queries/cantidadNFUsPorTipoYMunicipioGQL";
export { CantidadNFUsPorTipoYCatGQL } from "./queries/cantidadNFUsPorTipoYCatGQL";
export { MunicipiosGQL } from "./queries/municipiosGQL";
export { PreliminarNuevosExpedientesNFUs } from "./queries/preliminarNuevosExpedientesNFUs";
export { GestionesPorExpedienteGQL } from "./queries/gestionesPorExpedienteGQL";
export { TiposGestionGQL } from "./queries/tiposGestionGQL";
export { StockNFUsDestruidosEnCatGQL } from "./queries/stockNFUsDestruidosEnCat";
