import { Injectable, OnDestroy } from "@angular/core";
import { from } from "rxjs";
import { environment } from "../../../environments/environment";
import { AutoUnsub } from "src/app/decorators/AutoUnsubDecorator";

@Injectable({
  providedIn: "root",
})
@AutoUnsub()
export class SlackService implements OnDestroy {
  private webHook = "";

  constructor() {
    this.webHook = environment.slack.webhook;
  }

  ngOnDestroy() {}

  postErrorOnSlack(error: Error, title: string): void {
    const message = {
      channel: "#angular",
      text: error.message,
      attachments: [
        {
          author_name: window.location.href,
          color: "danger",
          title: title,
          text: error.stack,
        },
      ],
    };

    from(
      // wrap the fetch in a from if you need an rxjs Observable
      fetch(this.webHook, {
        body: JSON.stringify(message),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "no-cors",
      })
    ).subscribe();
  }
}
