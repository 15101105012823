import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryTiposDocumentoArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class TiposDocumentoGQL extends Query<
  TypeQuery,
  QueryTiposDocumentoArgs
> {
  document = gql`
    query tiposDocumento($filtros: Filtros) {
      tiposDocumento(filtros: $filtros) {
        id
        nombre
      }
    }
  `;
}
