import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationActualizarEmpresaGeneradoraArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class ActualizarEmpresaGeneradoraGQL extends Mutation<
  TypeMutation,
  MutationActualizarEmpresaGeneradoraArgs
> {
  document = gql`
    mutation actualizarEmpresaGeneradora(
      $data: ActualizarEmpresaGeneradoraInput!
    ) {
      actualizarEmpresaGeneradora(data: $data) {
        id
        notifica
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            piso
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        marcasNeumatico {
          id
          nombre
        }
      }
    }
  `;
}
