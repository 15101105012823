import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationEgresarNfUsArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class EgresarNFUsGQL extends Mutation<
  TypeMutation,
  MutationEgresarNfUsArgs
> {
  document = gql`
    mutation egresarNFUs($idCat: ID!, $data: EgresarNFUsInput!) {
      egresarNFUs(idCat: $idCat, data: $data) {
        id
        fecha
        tipoComprobante {
          id
          nombre
        }
      }
    }
  `;
}
