import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class ItemsGQL extends Query<TypeQuery> {
  document = gql`
    query items {
      items {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
        link
        itemPadre {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        itemHijos {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        permisos {
          id
          nombre
          descripcion
        }
      }
    }
  `;
}
