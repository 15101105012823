import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryMarcasNeumaticoArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class MarcasNeumaticoGQL extends Query<
  TypeQuery,
  QueryMarcasNeumaticoArgs
> {
  document = gql`
    query marcasNeumatico($filtros: Filtros) {
      marcasNeumatico(filtros: $filtros) {
        id
        nombre
        empresaGeneradora {
          id
          persona {
            id
            nombre
          }
        }
      }
    }
  `;
}
