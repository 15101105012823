import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class RolesGQL extends Query<TypeQuery> {
  document = gql`
    query roles {
      roles {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
        permisos {
          id
          nombre
          descripcion
          fechaCreacion
          fechaActualizacion
        }
      }
    }
  `;
}
