import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "../../../environments/environment";
import { User, AuthPayload } from "../../graphql/Graphql";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  returnUrl: string;
  usu: User;

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const accessToken: AuthPayload = <AuthPayload>(
      JSON.parse(
        localStorage.getItem(`auth_payload_${btoa(environment.environment)}`)
      )
    );

    if (accessToken && accessToken.access_token) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
