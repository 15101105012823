import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryTiposGestionArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class TiposGestionGQL extends Query<TypeQuery, QueryTiposGestionArgs> {
  document = gql`
    query tiposGestion($filtros: Filtros) {
      tiposGestion(filtros: $filtros) {
        id
        descripcion
        fechaCreacion
      }
    }
  `;
}
