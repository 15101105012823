import { Injectable, OnDestroy } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { AutoUnsub } from "src/app/decorators/AutoUnsubDecorator";
import { Cat, Provincia } from "src/app/graphql/Graphql";
import { CatsGQL } from "src/app/graphql/queries/catsGQL";
import { ProvinciasGQL } from "src/app/graphql/queries/provinciasGQL";

@Injectable({
  providedIn: "root",
})
@AutoUnsub()
export class SharedService implements OnDestroy {
  listadoCatsUsuario$ = this.getCatsUsuario();
  constructor(private provinciasGQL: ProvinciasGQL, private catsGQL: CatsGQL) {}

  getProvincias(): Observable<Provincia[]> {
    return this.provinciasGQL
      .watch()
      .valueChanges.pipe(map(({ data }) => data?.provincias));
  }

  getCatsUsuario(): Observable<Cat[]> {
    return this.catsGQL.watch().valueChanges.pipe(
      map(({ data }) => data?.cats),
      catchError((err) => throwError(err)),
      shareReplay(1)
    );
  }

  ngOnDestroy(): void {}
}
