import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationEliminarExpedienteArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarExpedienteGQL extends Mutation<
  TypeMutation,
  MutationEliminarExpedienteArgs
> {
  document = gql`
    mutation eliminarExpediente($data: EliminarExpedienteInput!) {
      eliminarExpediente(data: $data) {
        id
        nroExpediente
      }
    }
  `;
}
