import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryProvinciasArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class ProvinciasGQL extends Query<TypeQuery, QueryProvinciasArgs> {
  document = gql`
    query provincias($filtros: Filtros) {
      provincias(filtros: $filtros) {
        id
        nombre
      }
    }
  `;
}
