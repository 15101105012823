import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationCrearPersonaArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearPersonaGQL extends Mutation<
  TypeMutation,
  MutationCrearPersonaArgs
> {
  document = gql`
    mutation crearPersona($data: CrearPersonaInput!) {
      crearPersona(data: $data) {
        id
        nroDocumento
        apellido
        nombre
        email
        telefono
        domicilio {
          id
          direccion
          numero
          piso
          lat
          lng
          ciudad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
        }
        tipoPersona {
          id
          nombre
        }
        tipoDocumento {
          id
          nombre
        }
      }
    }
  `;
}
