import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ErroresService {
  message: string;

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return "No tiene conexión con internet";
    }
    this.message = error.message ? error.message : error.toString();

    this.message = this.message.replace("GraphQL error:", "Error:");

    return this.message;
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return "¡Error al querer comunicarse con el servidor!";
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return "stack";
  }
}
