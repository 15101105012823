import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  menu: any = [
    {
      titulo: "Personas/Usuarios",
      icono: "fa fa-users",
      submenu: [
        { titulo: "Datos Persona", url: "/personas" },
        { titulo: "Datos Usuario", url: "/usuariso" },
        { titulo: "Datos Municipio", url: "/municipios" },
        { titulo: "Datos Empresas", url: "/empresas" },
        //  { titulo: 'Datos Usuarios', url: '/dashboard'},
        //  { titulo: 'Crear Persona', url: '/dashboard'},
        //  { titulo: 'Crear Persona', url: '/dashboard'},
        //  { titulo: 'ProgressBar', url: '/progress'},
        //  { titulo: 'Gráficas', url: '/graficas1'},
        //  { titulo: 'Promesas ', url: '/promesas'},
        //  { titulo: 'Rxjs ', url: '/rxjs'}
      ],
    },
    {
      titulo: "C.A.T.",
      icono: "mdi mdi-gauge",
      submenu: [
        // { titulo: 'Crear Cat', url: '/dashboard'},
        // { titulo: 'Buscar Cat', url: '/dashboard'},
        { titulo: "Ingreso NFU's", url: "/ingresonfus" },
        { titulo: "Stock", url: "/stock" },
        { titulo: "Comprobantes", url: "/manifiestos" },
        { titulo: "Retiro NFU's", url: "/retironfus" },
        // { titulo: 'Stok', url: '/dashboard'},
        // { titulo: 'Cierre del día', url: '/dashboard'},
        // { titulo: 'Retiro NFU\'\s', url: '/dashboard'},
        // { titulo: 'Retiro NFU\'\s', url: '/dashboard'},
        // { titulo: 'ProgressBar', url: '/progress'},
        // { titulo: 'Gráficas', url: '/graficas1'}
        // { titulo: 'Promesas ', url: '/promesas'},
        // { titulo: 'Rxjs ', url: '/rxjs'}
      ],
    },
    {
      titulo: "Datos Personales",
      icono: "mdi mdi-gauge",
      submenu: [
        { titulo: "Dashboard", url: "/dashboard" },
        { titulo: "Cofiguración", url: "/account-settings" },
        { titulo: "ProgressBar", url: "/progress" },
        { titulo: "Gráficas", url: "/graficas1" },
        { titulo: "Promesas ", url: "/promesas" },
        { titulo: "Rxjs ", url: "/rxjs" },
      ],
    },
  ];
  constructor() {}
}
