import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation } from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class LogoutGQL extends Mutation<TypeMutation> {
  document = gql`
    mutation salir {
      salir {
        message
        status
      }
    }
  `;
}
