import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryExpedientesNfUsArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class ExpedientesNFUsGQL extends Query<
  TypeQuery,
  QueryExpedientesNfUsArgs
> {
  document = gql`
    query expedientesNFUs($idCat: ID) {
      expedientesNFUs(idCat: $idCat) {
        id
        nroExpediente
        municipio
        fechaCreacion
        fechaDisponibleRetiro
        estado
        empresaGeneradora
        usuarioUltimaModificacion
        fechaUltimaModificacion
        fechaNotificacion
        urlPrimeraNotificacion
        total
        vencimientoPrimeraNotificacion
      }
    }
  `;
}
