import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ItemsGQL } from "src/app/graphql/queries/itemsGQL";
import { Item } from "../../graphql/Graphql";

@Injectable({
  providedIn: "root",
})
export class AuthGuardChildren implements CanActivate {
  returnUrl: string;
  items: Item[];
  pathRol: string;

  constructor(private itemsGQL: ItemsGQL) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    localStorage.removeItem("permisos");

    return this.itemsGQL.watch().valueChanges.pipe(
      map(({ data }) => {
        this.items = data?.items.filter((it) => {
          return it.itemPadre !== undefined;
        });
        const itemSeleccionado: Item = this.items.find(
          (itH) => itH.link === route.data.path
        );
        if (itemSeleccionado && itemSeleccionado.permisos)
          localStorage.setItem(
            "permisos",
            JSON.stringify(itemSeleccionado.permisos)
          );
        // Valida que tenga permiso al item ingresado
        return (
          this.items.find((itH) => itH.link === route.data.path) !== undefined
        );
      }),
      catchError((err) => {
        localStorage.removeItem("permisos");
        return throwError(err);
      })
    );
  }
}
