import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { QueryStockNfUsArgs, Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class StockNFUsGQL extends Query<TypeQuery, QueryStockNfUsArgs> {
  document = gql`
    query stockNFUs($filtros: StockNFUsInput) {
      stockNFUs(filtros: $filtros) {
        municipio
        cat
        empresa
        tipoNeumatico
        marcaNeumatico
        estado
        cantidad
        cantidadLiberada
      }
    }
  `;
}
