import { Apollo } from "apollo-angular";
import { InMemoryCache } from "@apollo/client/core";
import { HttpClientModule } from "@angular/common/http";
import { HttpLink } from "apollo-angular/http";
import { onError } from "@apollo/client/link/error";
import { AuthPayload } from "./graphql/Graphql";
import Pusher from "pusher-js";
import PusherLink from "./pusher-link";
import { RefreshTokenGQL } from "./graphql/mutaciones/refrescarTokenGQL";
import { filter, map, take } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { ApolloLink, Observable } from "@apollo/client/core";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [HttpClientModule],
})
export class GraphQLModule {
  authPayload?: AuthPayload;
  static isRefreshing;
  static refreshTokenSubject: BehaviorSubject<
    AuthPayload
  > = new BehaviorSubject<AuthPayload>(null);

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private refreshTokenGQL: RefreshTokenGQL
  ) {
    GraphQLModule.isRefreshing = false;

    const http = this.httpLink.create({
      uri: `${environment.apiUrl}`,
    });

    this.authPayload =
      localStorage.getItem(`auth_payload_${btoa(environment.environment)}`) !==
      null
        ? <AuthPayload>(
            JSON.parse(
              localStorage.getItem(
                `auth_payload_${btoa(environment.environment)}`
              )
            )
          )
        : null;

    const authLink = new ApolloLink((operation, forward) => {
      // Get the authentication token from local storage if it exists
      const accessToken: AuthPayload = <AuthPayload>(
        JSON.parse(
          localStorage.getItem(`auth_payload_${btoa(environment.environment)}`)
        )
      );

      // Use the setContext method to set the HTTP headers.
      operation.setContext({
        headers: {
          authorization: accessToken
            ? `Bearer ${accessToken.access_token}`
            : "",
        },
        useMultipart: true,
      });

      // Call the next link in the middleware chain.
      return forward(operation);
    });

    let pusherLink: PusherLink = new PusherLink(
      {
        pusher: new Pusher(environment.pusher.key, {
          cluster: environment.pusher.cluster,
          forceTLS: environment.pusher.forceTLS,
          authEndpoint: `${environment.pusher.authEndpoint}`,
          enableStats: environment.pusher.enableStats,
        }),
      },
      environment.pusher.logToConsole
    );

    const promiseToObservable = (promise: Promise<any>) =>
      new Observable((subscriber: any) => {
        promise.then(
          (value) => {
            if (subscriber.closed) {
              return;
            }
            subscriber.next(value);
            subscriber.complete();
          },
          (err) => subscriber.error(err)
        );
      });

    const refreshAuthToken = (refreshToken: string) => {
      // Esto solo se ejecuta una vez y las demas consultas esperan a que se actualize el token para tomarlo
      if (!GraphQLModule.isRefreshing) {
        GraphQLModule.isRefreshing = true;
        GraphQLModule.refreshTokenSubject.next(null);

        return this.refreshTokenGQL
          .mutate({
            data: {
              refresh_token: refreshToken,
            },
          })
          .pipe(
            take(1),
            map(({ data }) => {
              if (data && data.refrescarToken) {
                localStorage.setItem(
                  `auth_payload_${btoa(environment.environment)}`,
                  JSON.stringify(data.refrescarToken)
                );
                this.authPayload =
                  localStorage.getItem(
                    `auth_payload_${btoa(environment.environment)}`
                  ) !== null
                    ? <AuthPayload>(
                        JSON.parse(
                          localStorage.getItem(
                            `auth_payload_${btoa(environment.environment)}`
                          )
                        )
                      )
                    : null;
                GraphQLModule.isRefreshing = false;
                return GraphQLModule.refreshTokenSubject.next(
                  data?.refrescarToken
                );
              }
              localStorage.removeItem(
                `auth_payload_${btoa(environment.environment)}`
              );
              this.authPayload = null;
            })
          );
      } else {
        return GraphQLModule.refreshTokenSubject.pipe(
          filter((refrescarToken) => refrescarToken != null),
          take(1),
          map((refrescarToken) => {
            localStorage.setItem(
              `auth_payload_${btoa(environment.environment)}`,
              JSON.stringify(refrescarToken)
            );
            return;
          })
        );
      }
    };

    const linkErrors = onError(
      ({ forward, graphQLErrors, networkError, response, operation }) => {
        this.authPayload =
          localStorage.getItem(
            `auth_payload_${btoa(environment.environment)}`
          ) !== null
            ? <AuthPayload>(
                JSON.parse(
                  localStorage.getItem(
                    `auth_payload_${btoa(environment.environment)}`
                  )
                )
              )
            : null;
        if (
          !!graphQLErrors.find(
            (e) =>
              this.authPayload !== null &&
              e.path &&
              e.path[0] === "refrescarToken"
          )
        ) {
          localStorage.removeItem(
            `auth_payload_${btoa(environment.environment)}`
          );
          this.authPayload = null;
        }

        if (
          !!graphQLErrors.find(
            (e) =>
              this.authPayload !== null &&
              new Date() > new Date(this.authPayload.expires_in) &&
              !(e.path && e.path[0] === "ingresar")
          )
        ) {
          return promiseToObservable(
            refreshAuthToken(this.authPayload?.refresh_token ?? "")
              .toPromise()
              .catch(() => {
                localStorage.removeItem(
                  `auth_payload_${btoa(environment.environment)}`
                );
                this.authPayload = null;
              })
          ).flatMap(() => forward(operation));
        }

        if (operation.operationName === "IgnoreErrorsQuery") {
          response.errors = null;
        }
        if (graphQLErrors)
          graphQLErrors.map(({ message, extensions: { category } }) => {
            switch (category) {
              case "internal":
                break;
              case "emergency":
                break;
              case "alert":
                break;
              case "critical":
                break;
              case "error":
                break;
              case "warning":
                break;
              case "notice":
                break;
              case "info":
                break;
              case "debug":
                break;
              case "authorization":
                break;
              case "ignore":
                return;
              case "UNAUTHENTICATED": {
                if (
                  !!graphQLErrors.find(
                    (e) => !(e.path && e.path[0] === "ingresar")
                  )
                ) {
                  return (graphQLErrors = null);
                }
                graphQLErrors[0].message =
                  "El usuario y/o la contraseña son/es incorrecta/s";
                return;
              }
              default: {
                if (
                  localStorage.getItem(
                    `auth_payload_${btoa(environment.environment)}`
                  ) === null
                )
                  return;
                throw new Error(message);
              }
            }
            throw new Error(`capturado|${message}`);
          });

        if (networkError) throw networkError;
      }
    );

    this.apollo.create({
      link: ApolloLink.from([linkErrors, pusherLink, authLink, http]),
      cache: new InMemoryCache(),
      connectToDevTools: !environment.production,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "network-only",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "network-only",
          errorPolicy: "all",
        },
      },
    });
  }
}
