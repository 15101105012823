// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: "dev",
  apiUrl: "https://api-dev.wormsargentina.com.ar/graphql",
  baseDocumentoUrl:
    "https://wormsargentina-stage.sfo2.digitaloceanspaces.com/dev/documentos/",
  pusher: {
    key: "78701a947960af8aa818",
    cluster: "us2",
    forceTLS: false,
    authEndpoint:
      "https://api-dev.wormsargentina.com.ar/graphql/subscriptions/auth",
    enableStats: true,
    logToConsole: true,
  },
  slack: {
    webhook:
      "https://hooks.slack.com/services/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",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
