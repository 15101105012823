import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import {
  Query as TypeQuery,
  QueryPreliminarNuevosExpedientesNfUsArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class PreliminarNuevosExpedientesNFUs extends Query<
  TypeQuery,
  QueryPreliminarNuevosExpedientesNfUsArgs
> {
  document = gql`
    query preliminarNuevosExpedientesNFUs(
      $fechaHasta: Date!
      $idsMunicipios: [ID!]!
      $idsMarcas: [ID!]!
    ) {
      preliminarNuevosExpedientesNFUs(
        fechaHasta: $fechaHasta
        idsMunicipios: $idsMunicipios
        idsMarcas: $idsMarcas
      ) {
        id
        total
        vencimiento
        detalles {
          nroManifiesto
          nroDetalleManifiesto
          fecha
          cantidad
          marcaNeumatico
          tipoNeumatico
          tasaUnitaria
          tasa
        }
        detallesTotales {
          cantidad
          marcaNeumatico
          tipoNeumatico
          tasaUnitaria
          tasa
        }
        empresa {
          id
          razonSocial
          cuit
          domicilio
          cp
          localidad
          provincia
        }
        cat {
          id
          calle
          cp
          ciudad
          provincia
          horariosAtencion
        }
        municipio {
          id
          nombre
          calle
          cp
          ciudad
          provincia
          logo
          ordenanza
          email
          datosDePagoPorTransferencia
          tipo_localidad
          firmante
        }
      }
    }
  `;
}
