import { Pipe, PipeTransform } from "@angular/core";
import { Comprobante } from "src/app/graphql/Graphql";

@Pipe({
  name: "tipoComprobante",
})
export class TipoComprobantePipe implements PipeTransform {
  transform(value: Comprobante[]): any {
    return value.filter((c) => c.tipoComprobante.id === "1");
  }
}
