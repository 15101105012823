import { MoverEstadoExpedienteGQL } from "./../../graphql/mutaciones/moverEstadoExpedienteGQL";
import { map } from "rxjs/operators";
import { EstadosExpedientesGQL } from "../../graphql/queries/estadosExpedientesGQL";
import { ExpedientesNFUsGQL } from "../../graphql/queries/expedientesNFUsGQL";
import {
  EstadoExpediente,
  ExpedienteReporte,
  MovimientoExpediente,
} from "../../graphql/Graphql";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { MovimientosExpedienteGQL } from "src/app/graphql/query.index";

@Injectable({
  providedIn: "root",
})
export class ExpedientesLiquidacionService {
  constructor(
    private expedientesNFUsGQL: ExpedientesNFUsGQL,
    private estadosExpedientesGQL: EstadosExpedientesGQL,
    private movimientoExpedienteGQL: MovimientosExpedienteGQL,
    private moverEstadoExpedienteGQL: MoverEstadoExpedienteGQL
  ) {}

  getExpedientes(id: string): Observable<ExpedienteReporte[]> {
    return this.expedientesNFUsGQL.watch({ idCat: id }).valueChanges.pipe(
      map(({ data: { expedientesNFUs } }) => {
        return expedientesNFUs;
      })
    );
  }

  getmoviemientoExpediente(id: number): Observable<MovimientoExpediente[]> {
    return this.movimientoExpedienteGQL
      .watch({ idExpediente: id.toString() })
      .valueChanges.pipe(map(({ data }) => data?.movimientosExpediente));
  }

  getEstados(): Observable<EstadoExpediente[]> {
    return this.estadosExpedientesGQL
      .watch({ idTipoServicio: "1" })
      .valueChanges.pipe(map(({ data }) => data?.estadosExpedientes));
  }

  editarExpediente(
    idExpediente: number,
    idNuevoEstado: number,
    observaciones: string,
    detalle?: string
  ): Observable<MovimientoExpediente> {
    return this.moverEstadoExpedienteGQL
      .mutate({
        data: {
          idExpediente: idExpediente.toString(),
          idEstadoNuevo: idNuevoEstado.toString(),
          observaciones: observaciones,
        },
      })
      .pipe(map(({ data }) => data?.moverEstadoExpediente));
  }
}
