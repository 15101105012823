import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationBorrarPersonaArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarPersonaGQL extends Mutation<
  TypeMutation,
  MutationBorrarPersonaArgs
> {
  document = gql`
    mutation borrarPersona($id: ID!) {
      borrarPersona(id: $id) {
        id
        nroDocumento
        apellido
        nombre
        email
        telefono
        domicilio {
          id
          direccion
          numero
          piso
          lat
          lng
          ciudad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
        }
        tipoPersona {
          id
          nombre
        }
        tipoDocumento {
          id
          nombre
        }
      }
    }
  `;
}
