import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryEstadosExpedientesArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EstadosExpedientesGQL extends Query<
  TypeQuery,
  QueryEstadosExpedientesArgs
> {
  document = gql`
    query estadosExpedientes($idTipoServicio: ID!) {
      estadosExpedientes(idTipoServicio: $idTipoServicio) {
        id
        nombre
        descripcion
        esEstadoInicial
        esEstadoFinal
      }
    }
  `;
}
