import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryTiposNeumaticoArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class TiposNeumaticoGQL extends Query<
  TypeQuery,
  QueryTiposNeumaticoArgs
> {
  document = gql`
    query tiposNeumatico($filtros: Filtros) {
      tiposNeumatico(filtros: $filtros) {
        id
        nombre
      }
    }
  `;
}
