import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryMovimientosExpedienteArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class MovimientosExpedienteGQL extends Query<
  TypeQuery,
  QueryMovimientosExpedienteArgs
> {
  document = gql`
    query movimientosExpediente($idExpediente: ID!) {
      movimientosExpediente(idExpediente: $idExpediente) {
        id
        detalle
        observaciones
        fechaEstado
        estado
        estadoAnterior
        responsable
      }
    }
  `;
}
