import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import * as uuid from "uuid";

import { LoggingService } from "./services/errores/slack-logging.service";
import { ErroresService } from "./services/errores/errores.service";
import { NotificationService } from "./services/errores/notification.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.

  private message: string;

  constructor(
    private erroresService: ErroresService,
    private logger: LoggingService,
    private notifier: NotificationService
  ) {
    this.message = `Comuniquese con sistemas con el siguiente código: "${uuid.v4()}"`;
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error.message.startsWith("capturado|")) {
      this.message = error.message.replace("capturado|", "");
      error = new Error(this.message);
    }

    // Always log errors
    this.logger.logError(error, this.message);

    //let stackTrace;
    if (error instanceof HttpErrorResponse) {
      // Server Error
      this.message = this.erroresService.getServerMessage(error);
      this.notifier.showError(this.message);
    } else {
      // Client Error
      this.notifier.showError(this.message);
    }
  }
}
