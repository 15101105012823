import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class PermisosGQL extends Query<TypeQuery> {
  document = gql`
    query permisos {
      permisos {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
      }
    }
  `;
}
