import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "itemnsMenu",
})
export class ItemnsMenuPipe implements PipeTransform {
  transform(value: any): any {
    return "objeto";
  }
}
