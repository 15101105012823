import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation, MutationCrearUserArgs } from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearUserGQL extends Mutation<
  TypeMutation,
  MutationCrearUserArgs
> {
  document = gql`
    mutation crearUser($data: CrearUserInput!) {
      crearUser(data: $data) {
        id
        name
        username
        superAdmin
        email
        cats {
          id
          persona {
            id
            nombre
            domicilio {
              id
              direccion
            }
          }
          municipio {
            id
            persona {
              id
              nombre
            }
          }
        }
        roles {
          id
          nombre
          descripcion
        }
        permisos {
          id
          nombre
          descripcion
        }
        persona {
          id
          nroDocumento
          nombre
          apellido
          email
          telefono
          logo
          firma
          tipoPersona {
            id
            nombre
          }
          tipoDocumento {
            id
            nombre
          }
          domicilio {
            id
            direccion
            numero
            piso
            lat
            lng
            principal
            ciudad {
              id
            }
          }
          tipoDocumento {
            id
          }
        }
        accessTokens {
          id
          fechaAccesso
        }
      }
    }
  `;
}
