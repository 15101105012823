import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Item, Permiso } from "src/app/graphql/Graphql";
import {
  ActualizarItemGQL,
  CrearItemGQL,
  EliminarItemGQL,
} from "src/app/graphql/mutation.index";
import { ItemsGQL } from "../../graphql/queries/itemsGQL";

@Injectable({
  providedIn: "root",
})
export class ItemsService {
  items: Item[];

  constructor(
    private actualizarItemGQL: ActualizarItemGQL,
    private crearItemGQL: CrearItemGQL,
    private eliminarItemGQL: EliminarItemGQL,
    private itemsGQL: ItemsGQL
  ) {}

  getItems(): Observable<Item[]> {
    return this.itemsGQL.watch().valueChanges.pipe(
      map(({ data }) => {
        return data?.items;
      })
    );
  }

  createItem(
    nomItem: string,
    descItem: string,
    linkItem: string,
    p: Permiso[],
    idPadre: string
  ): Observable<Item> {
    let datos: any = {
      nombre: nomItem,
      descripcion: descItem,
      link: linkItem,
      permisos: {
        sync: p.map((per) => per.id),
      },
    };

    if (idPadre) {
      datos = {
        ...datos,
        itemPadre: {
          connect: idPadre,
        },
      };
    }

    return this.crearItemGQL
      .mutate({
        data: datos,
      })
      .pipe(map(({ data }) => data?.crearItem));
  }

  actualizarItem(
    idItem,
    nomItem,
    descItem,
    linkItem,
    p: Permiso[],
    idPadre: string
  ): Observable<Item> {
    let datos: any = {
      id: idItem,
      nombre: nomItem,
      descripcion: descItem,
      link: linkItem,
      permisos: {
        sync: p.map((per) => per.id),
      },
    };

    if (idPadre) {
      datos = {
        ...datos,
        itemPadre: {
          connect: idPadre,
        },
      };
    }

    return this.actualizarItemGQL
      .mutate({
        data: datos,
      })
      .pipe(map(({ data }) => data?.actualizarItem));
  }

  deleteItem(idItem): Observable<Item> {
    return this.eliminarItemGQL
      .mutate({
        id: idItem,
      })
      .pipe(map(({ data }) => data?.borrarItem));
  }
}
