import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class CantidadNFUsPorTipoYCatGQL extends Query<TypeQuery> {
  document = gql`
    query cantidadNFUsPorTipoYCat {
      cantidadNFUsPorTipoYCat {
        name
        series {
          name
          value
        }
      }
    }
  `;
}
