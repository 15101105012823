import { Injectable, OnInit } from "@angular/core";
import {
  NuevoEgresoNFUsGQL,
  NuevoIngresoNFUsGQL,
} from "src/app/graphql/subscription.index";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class ComprobantesService {
  constructor(
    private nuevoIngresoNFUsGQL: NuevoIngresoNFUsGQL,
    private nuevoEgresoNFUsGQL: NuevoEgresoNFUsGQL
  ) {}

  public suscribirseNuevoIngresoNFUs() {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 6000,
    });

    this.nuevoIngresoNFUsGQL.subscribe().subscribe(({ data }) => {
      Toast.fire({
        type: "info",
        title: `Ingreso de NFUs`,
        html: `<p class="fw-light">Nro. Comprobante ${data?.nuevoIngresoNFUs?.id}</p>
        <p class="fw-light">CAT ${data?.nuevoIngresoNFUs?.cat?.persona?.nombre}</p>
        <p class="fw-light">Nro. Comprobante ${data?.nuevoIngresoNFUs?.id}</p>`,
      });
    });
  }

  public suscribirseNuevoEgresoNFUs() {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 6000,
    });

    this.nuevoEgresoNFUsGQL.subscribe().subscribe(({ data }) => {
      Toast.fire({
        type: "info",
        title: `Egreso de NFUs`,
        html: `
          <p class="fw-light">Nro. Comprobante ${data?.nuevoIngresoNFUs?.id}</p>
          <p class="fw-light">CAT ${data?.nuevoIngresoNFUs?.cat?.persona?.nombre}</p>
          <p class="fw-light">Nro. Comprobante ${data?.nuevoIngresoNFUs?.id}</p>
        `,
      });
    });
  }
}
