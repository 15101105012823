import { Permiso } from "./../../graphql/Graphql";
import { PermisosGQL } from "./../../graphql/queries/permisosGQL";
import { Injectable, OnDestroy } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthPayload, User } from "src/app/graphql/Graphql";
import { UsuarioActualGQL } from "src/app/graphql/queries/usuarioActualGQL";
import { IngresarGQL } from "../../graphql/mutation.index";
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";
import { AutoUnsub } from "src/app/decorators/AutoUnsubDecorator";
import { Promise } from "q";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
@AutoUnsub()
export class AuthService implements OnDestroy {
  constructor(
    private ingresarGQL: IngresarGQL,
    private usuarioActualGQL: UsuarioActualGQL,
    private permisosGQL: PermisosGQL,
    private apollo: Apollo,
    private ngxPermissionsService: NgxPermissionsService,
    private ngxRolesService: NgxRolesService,
    private router: Router
  ) {}

  ingresar(username: string, password: string): Observable<AuthPayload> {
    this.apollo.getClient().resetStore();

    return this.ingresarGQL
      .mutate({
        data: {
          username: username,
          password: password,
        },
      })
      .pipe(
        map(({ data }) => {
          if (data && data.ingresar) {
            localStorage.setItem(
              `auth_payload_${btoa(environment.environment)}`,
              JSON.stringify(data.ingresar)
            );
            if (data.ingresar.user) this.armaPermisos(data.ingresar.user);
          }
          return data?.ingresar;
        })
      );
  }

  salir() {
    this.ngxPermissionsService.flushPermissions();
    this.ngxRolesService.flushRolesAndPermissions();
    this.apollo.getClient().resetStore();
    this.apollo.client.stop();
    this.apollo.client.clearStore();
    localStorage.removeItem(`auth_payload_${btoa(environment.environment)}`);
  }

  public getUsuarioActual(): Observable<User> {
    return this.usuarioActualGQL.fetch().pipe(map(({ data }) => data?.yo));
  }

  public getPermisos(): Observable<Permiso[]> {
    return this.permisosGQL
      .watch()
      .valueChanges.pipe(map(({ data }) => data?.permisos));
  }

  public armaPermisos(usuario: User) {
    usuario?.roles?.forEach((r) =>
      this.ngxRolesService.addRole(`rol.${r.nombre}`, [])
    );

    if (usuario && usuario.superAdmin) {
      this.ngxRolesService.addRole("rol.super_admin", []);
      return this.getPermisos().subscribe((ps) => {
        if (ps && ps.length > 0)
          this.ngxPermissionsService.addPermission(
            ps?.map((p) => `permiso.${p.nombre}`)
          );
      });
    } else {
      usuario?.permisos?.forEach((p) =>
        this.ngxPermissionsService.addPermission(`permiso.${p.nombre}`)
      );
    }
  }

  loadUser(): Promise<any> {
    this.ngxPermissionsService.flushPermissions();
    this.ngxRolesService.flushRolesAndPermissions();
    return Promise<any>((resolve) => {
      this.getUsuarioActual().subscribe(
        (data) => {
          this.armaPermisos(data);
          resolve(true);
        },
        (err) => {
          this.router.navigate(["login"]);
          resolve(true);
        }
      );
    });
  }

  ngOnDestroy(): void {}
}
