import { Injectable } from "@angular/core";
import { Subscription, gql } from "apollo-angular";
import { Subscription as TypeSubscription } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarExpedienteGQL extends Subscription<TypeSubscription> {
  document = gql`
    subscription eliminarExpediente {
      eliminarExpediente {
        id
        nroExpediente
        municipio
        fechaCreacion
        estado
        empresaGeneradora
        usuarioUltimaModificacion
        fechaUltimaModificacion
        urlPrimeraNotificacion
        total
        vencimientoPrimeraNotificacion
      }
    }
  `;
}
