import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationDestruirNfUsArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class DestruirNFUsGQL extends Mutation<
  TypeMutation,
  MutationDestruirNfUsArgs
> {
  document = gql`
    mutation destruirNFUs($idCat: ID!, $data: DestruirNFUsInput!) {
      destruirNFUs(idCat: $idCat, data: $data) {
        id
        fecha
        tipoComprobante {
          id
          nombre
        }
      }
    }
  `;
}
