import { Component } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import Swal from "sweetalert2";
import { SettingsService } from "./services/service.index";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Worms Argentina";
  constructor(private _ajustes: SettingsService, private swUpdate: SwUpdate) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        Swal.fire({
          title: "Hay una nueva versión de la aplicación",
          type: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Cargar",
          confirmButtonClass: "btn btn-success",
        }).then(function () {
          window.location.reload();
        });
      });
    }
    this._ajustes.cargarAjustes();
  }
}
