import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation, MutationCrearItemArgs } from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearItemGQL extends Mutation<
  TypeMutation,
  MutationCrearItemArgs
> {
  document = gql`
    mutation crearItem($data: CrearItemInput!) {
      crearItem(data: $data) {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
        link
        itemPadre {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        itemHijos {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        permisos {
          id
          nombre
          descripcion
        }
      }
    }
  `;
}
