import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation, MutationBorrarItemArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarItemGQL extends Mutation<
  TypeMutation,
  MutationBorrarItemArgs
> {
  document = gql`
    mutation borrarItem($id: ID!) {
      borrarItem(id: $id) {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
        link
        itemPadre {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        itemHijos {
          id
          nombre
          descripcion
          link
          permisos {
            id
            nombre
            descripcion
          }
        }
        permisos {
          id
          nombre
          descripcion
        }
      }
    }
  `;
}
