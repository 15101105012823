import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationCrearEmpresaArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearEmpresaGQL extends Mutation<
  TypeMutation,
  MutationCrearEmpresaArgs
> {
  document = gql`
    mutation crearEmpresa($data: CrearEmpresaInput!) {
      crearEmpresa(data: $data) {
        id
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
          tipoPersona {
            id
            nombre
          }
          tipoDocumento {
            id
            nombre
          }
        }
      }
    }
  `;
}
