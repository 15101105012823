import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryPersonaArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class PersonaGQL extends Query<TypeQuery, QueryPersonaArgs> {
  document = gql`
    query persona($tipoDocumento: ID!, $nroDocumento: String!) {
      persona(tipoDocumento: $tipoDocumento, nroDocumento: $nroDocumento) {
        id
        nroDocumento
        apellido
        nombre
        email
        telefono
        domicilio {
          id
          direccion
          numero
          piso
          lat
          lng
          ciudad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
        }
        tipoPersona {
          id
          nombre
        }
        tipoDocumento {
          id
          nombre
        }
      }
    }
  `;
}
