import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import {
  Query as TypeQuery,
  QueryEstadosPosibleDeEstadoActualArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EstadosPosibleDeEstadoActualGQL extends Query<
  TypeQuery,
  QueryEstadosPosibleDeEstadoActualArgs
> {
  document = gql`
    query estadosPosibleDeEstadoActual($idExpediente: ID!) {
      estadosPosibleDeEstadoActual(idExpediente: $idExpediente) {
        id
        nombre
        descripcion
        esEstadoInicial
        esEstadoFinal
      }
    }
  `;
}
