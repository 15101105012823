import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationCrearGestionExpedienteArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CrearGestionExpedienteGQL extends Mutation<
  TypeMutation,
  MutationCrearGestionExpedienteArgs
> {
  document = gql`
    mutation crearGestionExpediente($data: CrearGestionExpedienteInput!) {
      crearGestionExpediente(data: $data) {
        id
        descripcion
        tipoGestion {
          id
          descripcion
        }
        fechaCreacion
        autor {
          id
          username
        }
      }
    }
  `;
}
