import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format 'Y-m-d H:i:s', e.g. '2018-01-01 13:00:00'. */
  DateTime: any;
  /** Necesario para whereConstraints */
  Mixed: any;
  /** A [RFC 5321](https://tools.ietf.org/html/rfc5321) compliant email. */
  Email: any;
  /** A date string with format 'Y-m-d', e.g. '2011-05-23'. */
  Date: any;
  /** A [RFC 5321](https://tools.ietf.org/html/rfc5321) compliant email. */
  Json: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Archivo: any;
};

export type AccessToken = {
  __typename?: "AccessToken";
  id: Scalars["String"];
  user: User;
  fechaAccesso: Scalars["DateTime"];
};

export type ActualizarCatInput = {
  id: Scalars["ID"];
  persona?: Maybe<ActualizarPersonaRelacion>;
  municipio?: Maybe<ActualizarMunicipioRelacion>;
  horarios_atencion?: Maybe<Scalars["String"]>;
};

export type ActualizarCatRelacion = {
  sync: Array<Scalars["ID"]>;
  disconnect?: Maybe<Array<Scalars["ID"]>>;
};

export type ActualizarDomicilioInput = {
  id: Scalars["ID"];
  direccion?: Maybe<Scalars["String"]>;
  numero?: Maybe<Scalars["String"]>;
  piso?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  principal?: Maybe<Scalars["Boolean"]>;
  ciudad?: Maybe<ExistenteRelacion>;
  tipoDomicilio?: Maybe<ExistenteRelacion>;
  persona?: Maybe<ExistenteRelacion>;
};

export type ActualizarDomiciliosRelation = {
  create?: Maybe<CrearDomicilioInput>;
  update?: Maybe<ActualizarDomicilioInput>;
  delete?: Maybe<Scalars["Boolean"]>;
};

export type ActualizarEmpresaGeneradoraInput = {
  id: Scalars["ID"];
  notifica?: Maybe<Scalars["Boolean"]>;
  persona?: Maybe<ActualizarPersonaRelacion>;
  marcasNeumatico?: Maybe<ActualizarMarcasNeumaticoRelacion>;
};

export type ActualizarEmpresaInput = {
  id: Scalars["ID"];
  persona?: Maybe<ActualizarPersonaRelacion>;
};

export type ActualizarExpedienteRelacion = {
  connect?: Maybe<Scalars["ID"]>;
};

export type ActualizarGestionExpedienteInput = {
  id: Scalars["ID"];
  descripcion?: Maybe<Scalars["String"]>;
  tipoGestion?: Maybe<ActualizarTipoGestionRelacion>;
  expediente?: Maybe<ActualizarExpedienteRelacion>;
};

export type ActualizarItemInput = {
  id: Scalars["ID"];
  nombre?: Maybe<Scalars["String"]>;
  descripcion?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  itemPadre?: Maybe<ExistenteRelacion>;
  permisos?: Maybe<ActualizarPermisosRelacion>;
};

export type ActualizarMarcaNeumaticoInput = {
  id: Scalars["ID"];
  nombre: Scalars["String"];
};

export type ActualizarMarcasNeumaticoRelacion = {
  create?: Maybe<Array<CrearMarcaNeumaticoInput>>;
  update?: Maybe<Array<ActualizarMarcaNeumaticoInput>>;
  delete?: Maybe<Array<Scalars["ID"]>>;
};

export type ActualizarMunicipioInput = {
  id: Scalars["ID"];
  ordenanza?: Maybe<Scalars["String"]>;
  ordenanza_url?: Maybe<Scalars["Archivo"]>;
  persona?: Maybe<ActualizarPersonaRelacion>;
  medio_de_pago?: Maybe<Scalars["String"]>;
  email_notificacion?: Maybe<Scalars["Email"]>;
  dias_vencimiento_liquidacion?: Maybe<Scalars["Int"]>;
  cant_dias_antes_de_disp_para_retirar?: Maybe<Scalars["Int"]>;
  tasasDeTipoNeumatico?: Maybe<CrearTasasDeTipoNeumaticoRelacion>;
  tipoLocalidad?: Maybe<ExistenteRelacion>;
  firmante?: Maybe<Scalars["String"]>;
  firma_encargado?: Maybe<Scalars["String"]>;
  mostrar_firma?: Maybe<Scalars["Boolean"]>;
};

export type ActualizarMunicipioRelacion = {
  update?: Maybe<ActualizarMunicipioInput>;
};

export type ActualizarPermisosRelacion = {
  sync?: Maybe<Array<Scalars["ID"]>>;
  disconnect?: Maybe<Array<Scalars["ID"]>>;
};

export type ActualizarPersonaInput = {
  id: Scalars["ID"];
  nombre?: Maybe<Scalars["String"]>;
  apellido?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  firma?: Maybe<Scalars["String"]>;
  nro_documento?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  tipoPersona?: Maybe<ExistenteRelacion>;
  tipoDocumento?: Maybe<ExistenteRelacion>;
  domicilio?: Maybe<ActualizarDomiciliosRelation>;
};

export type ActualizarPersonaRelacion = {
  update?: Maybe<ActualizarPersonaInput>;
};

export type ActualizarRolesRelacion = {
  sync?: Maybe<Array<Scalars["ID"]>>;
  disconnect?: Maybe<Array<Scalars["ID"]>>;
};

export type ActualizarRolInput = {
  id: Scalars["ID"];
  nombre?: Maybe<Scalars["String"]>;
  descripcion: Scalars["String"];
  permisos?: Maybe<ActualizarPermisosRelacion>;
};

export type ActualizarTipoGestionRelacion = {
  connect?: Maybe<Scalars["ID"]>;
};

export type ActualizarUserInput = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Email"]>;
  password?: Maybe<Scalars["String"]>;
  persona?: Maybe<ActualizarPersonaRelacion>;
  roles?: Maybe<ActualizarRolesRelacion>;
  permisos?: Maybe<ActualizarPermisosRelacion>;
  cats?: Maybe<ActualizarCatRelacion>;
};

export type ActualizarVariableGlobalInput = {
  id: Scalars["ID"];
  nombre?: Maybe<Scalars["String"]>;
  valor?: Maybe<Scalars["String"]>;
  descripcion?: Maybe<Scalars["String"]>;
};

export type AuthPayload = {
  __typename?: "AuthPayload";
  access_token: Scalars["String"];
  refresh_token: Scalars["String"];
  expires_in: Scalars["DateTime"];
  token_type: Scalars["String"];
  user: User;
};

export type BorrarPersonaRelacion = {
  delete: Scalars["Boolean"];
};

export type CambiarFechaNotificacionExpedienteInput = {
  idExpediente: Scalars["ID"];
  fechaNotificacion?: Maybe<Scalars["Date"]>;
};

export type Cat = {
  __typename?: "Cat";
  id: Scalars["ID"];
  persona: Persona;
  municipio: Municipio;
  horariosAtencion?: Maybe<Scalars["String"]>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type CatExp = {
  __typename?: "CatExp";
  id?: Maybe<Scalars["ID"]>;
  calle?: Maybe<Scalars["String"]>;
  cp?: Maybe<Scalars["Int"]>;
  ciudad?: Maybe<Scalars["String"]>;
  provincia?: Maybe<Scalars["String"]>;
  horariosAtencion?: Maybe<Scalars["String"]>;
};

export type Ciudad = {
  __typename?: "Ciudad";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  codigoPostal: Scalars["Int"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  provincia: Provincia;
};

export type CiudadProvinciaArgs = {
  trashed?: Maybe<Trashed>;
};

export type Comprobante = {
  __typename?: "Comprobante";
  id: Scalars["ID"];
  fecha: Scalars["DateTime"];
  ubicacionFisica?: Maybe<Scalars["String"]>;
  observaciones?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Float"]>;
  tipoReciclador?: Maybe<TipoReciclador>;
  tipoComprobante: TipoComprobante;
  empresa?: Maybe<Empresa>;
  detallesComprobante: Array<Maybe<DetalleComprobante>>;
  cat: Cat;
  persona: Persona;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type ComprobanteTipoRecicladorArgs = {
  trashed?: Maybe<Trashed>;
};

export type ComprobanteTipoComprobanteArgs = {
  trashed?: Maybe<Trashed>;
};

export type ComprobanteEmpresaArgs = {
  trashed?: Maybe<Trashed>;
};

export type ComprobantePersonaArgs = {
  trashed?: Maybe<Trashed>;
};

export type CrearCatInput = {
  persona: CrearPersonaRelacion;
  municipio: CrearMunicipioRelacion;
  horarios_atencion: Scalars["String"];
};

export type CrearCiudadInput = {
  nombre: Scalars["String"];
  codigo_postal: Scalars["Int"];
  provincia?: Maybe<ExistenteRelacion>;
};

export type CrearDomicilioInput = {
  direccion: Scalars["String"];
  numero?: Maybe<Scalars["String"]>;
  piso?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  principal: Scalars["Boolean"];
  ciudad: ExistenteRelacion;
  tipoDomicilio: ExistenteRelacion;
  persona?: Maybe<ExistenteRelacion>;
};

export type CrearDomicilioRelacion = {
  create: CrearDomicilioInput;
};

export type CrearEmpresaGeneradoraInput = {
  notifica: Scalars["Boolean"];
  persona: CrearPersonaRelacion;
  marcasNeumatico: CrearMarcasNeumaticoRelacion;
};

export type CrearEmpresaInput = {
  persona: CrearPersonaRelacion;
};

export type CrearGestionExpedienteInput = {
  descripcion: Scalars["String"];
  tipoGestion: ActualizarTipoGestionRelacion;
  expediente: ActualizarExpedienteRelacion;
};

export type CrearItemInput = {
  nombre: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  descripcion?: Maybe<Scalars["String"]>;
  itemPadre?: Maybe<ExistenteRelacion>;
  permisos?: Maybe<ActualizarPermisosRelacion>;
};

export type CrearMarcaNeumaticoInput = {
  nombre: Scalars["String"];
};

export type CrearMarcasNeumaticoRelacion = {
  create: Array<CrearMarcaNeumaticoInput>;
};

export type CrearMunicipioInput = {
  ordenanza: Scalars["String"];
  ordenanza_url?: Maybe<Scalars["Archivo"]>;
  persona: CrearPersonaRelacion;
  medio_de_pago: Scalars["String"];
  email_notificacion: Scalars["Email"];
  dias_vencimiento_liquidacion: Scalars["Int"];
  cant_dias_antes_de_disp_para_retirar: Scalars["Int"];
  tasasDeTipoNeumatico: CrearTasasDeTipoNeumaticoRelacion;
  tipoLocalidad: ExistenteRelacion;
  firmante: Scalars["String"];
  firma_encargado?: Maybe<Scalars["String"]>;
  mostrar_firma?: Maybe<Scalars["Boolean"]>;
};

export type CrearMunicipioRelacion = {
  create: CrearMunicipioInput;
};

export type CrearPermisoInput = {
  nombre: Scalars["String"];
  descripcion: Scalars["String"];
};

export type CrearPersonaInput = {
  nombre: Scalars["String"];
  apellido?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  firma?: Maybe<Scalars["String"]>;
  nro_documento?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Email"]>;
  telefono?: Maybe<Scalars["String"]>;
  tipoPersona: ExistenteRelacion;
  tipoDocumento: ExistenteRelacion;
  domicilio?: Maybe<CrearDomicilioRelacion>;
};

export type CrearPersonaRelacion = {
  create: CrearPersonaInput;
};

export type CrearProvinciaInput = {
  nombre: Scalars["String"];
  codigo31662: Scalars["String"];
};

export type CrearRolInput = {
  nombre: Scalars["String"];
  descripcion: Scalars["String"];
  permisos?: Maybe<ActualizarPermisosRelacion>;
};

export type CrearTasaDeTipoNeumaticoInput = {
  tasa_unitaria: Scalars["Float"];
  tipoNeumatico: ExistenteRelacion;
};

export type CrearTasasDeTipoNeumaticoRelacion = {
  create: Array<CrearTasaDeTipoNeumaticoInput>;
};

export type CrearTipoDocumentoInput = {
  nombre: Scalars["String"];
};

export type CrearTipoDomicilioInput = {
  nombre: Scalars["String"];
};

export type CrearTipoLocalidadInput = {
  nombre: Scalars["String"];
};

export type CrearTipoPersonaInput = {
  nombre: Scalars["String"];
};

export type CrearUserInput = {
  name: Scalars["String"];
  username: Scalars["String"];
  email?: Maybe<Scalars["Email"]>;
  password: Scalars["String"];
  persona: CrearPersonaRelacion;
  roles: ActualizarRolesRelacion;
  permisos?: Maybe<ActualizarPermisosRelacion>;
  cats: ActualizarCatRelacion;
};

export type CrearVariableGlobalInput = {
  nombre: Scalars["String"];
  valor: Scalars["String"];
  descripcion: Scalars["String"];
};

export type DatosGrafico = {
  __typename?: "DatosGrafico";
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type DatosMultipleGrafico = {
  __typename?: "DatosMultipleGrafico";
  name?: Maybe<Scalars["String"]>;
  series?: Maybe<Array<Maybe<DatosGrafico>>>;
};

export type DestruirNfUsInput = {
  observaciones?: Maybe<Scalars["String"]>;
  kgsDeNFUsDestruidosEnCat: Scalars["Float"];
  NFUs: Array<NfuInput>;
};

export type DetalleComprobante = {
  __typename?: "DetalleComprobante";
  id: Scalars["ID"];
  cantidad: Scalars["Int"];
  tipoNeumatico: TipoNeumatico;
  marcaNeumatico: MarcaNeumatico;
  comprobante: Comprobante;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type DetalleComprobanteTipoNeumaticoArgs = {
  trashed?: Maybe<Trashed>;
};

export type DetalleComprobanteMarcaNeumaticoArgs = {
  trashed?: Maybe<Trashed>;
};

export type DetalleLiquidacionExp = {
  __typename?: "DetalleLiquidacionExp";
  nroManifiesto?: Maybe<Scalars["String"]>;
  nroDetalleManifiesto?: Maybe<Scalars["ID"]>;
  fecha?: Maybe<Scalars["DateTime"]>;
  cantidad?: Maybe<Scalars["Int"]>;
  marcaNeumatico?: Maybe<Scalars["String"]>;
  tipoNeumatico?: Maybe<Scalars["String"]>;
  tasaUnitaria?: Maybe<Scalars["Float"]>;
  tasa?: Maybe<Scalars["Float"]>;
};

export type DetalleTotalExp = {
  __typename?: "DetalleTotalExp";
  cantidad?: Maybe<Scalars["Int"]>;
  marcaNeumatico?: Maybe<Scalars["String"]>;
  tipoNeumatico?: Maybe<Scalars["String"]>;
  tasaUnitaria?: Maybe<Scalars["Float"]>;
  tasa?: Maybe<Scalars["Float"]>;
};

export type Domicilio = {
  __typename?: "Domicilio";
  id: Scalars["ID"];
  direccion: Scalars["String"];
  numero?: Maybe<Scalars["String"]>;
  piso?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  celular?: Maybe<Scalars["String"]>;
  email: Scalars["Email"];
  lat: Scalars["Float"];
  lng: Scalars["Float"];
  principal: Scalars["Boolean"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  ciudad: Ciudad;
  tipoDomicilio: TipoDomicilio;
};

export type DomicilioCiudadArgs = {
  trashed?: Maybe<Trashed>;
};

export type DomicilioTipoDomicilioArgs = {
  trashed?: Maybe<Trashed>;
};

export type EgresarNfUsInput = {
  idTransportista: Scalars["ID"];
  idEmpresa?: Maybe<Scalars["ID"]>;
  dominioVehiculo: Scalars["String"];
  observaciones?: Maybe<Scalars["String"]>;
  firma: Scalars["String"];
  kgsDeNFUsDestruidosEnCat?: Maybe<Scalars["Float"]>;
  NFUs?: Maybe<Array<NfuInput>>;
};

export type EliminarComprobanteInput = {
  idComprobante: Scalars["ID"];
};

export type EliminarExpedienteInput = {
  idExpediente: Scalars["ID"];
};

export type Empresa = {
  __typename?: "Empresa";
  id: Scalars["ID"];
  comprobantes?: Maybe<Array<Maybe<Comprobante>>>;
  persona: Persona;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type EmpresaPersonaArgs = {
  trashed?: Maybe<Trashed>;
};

export type EmpresaExp = {
  __typename?: "EmpresaExp";
  id?: Maybe<Scalars["ID"]>;
  razonSocial?: Maybe<Scalars["String"]>;
  cuit?: Maybe<Scalars["String"]>;
  domicilio?: Maybe<Scalars["String"]>;
  cp?: Maybe<Scalars["Int"]>;
  localidad?: Maybe<Scalars["String"]>;
  provincia?: Maybe<Scalars["String"]>;
};

export type EmpresaGeneradora = {
  __typename?: "EmpresaGeneradora";
  id: Scalars["ID"];
  notifica: Scalars["Boolean"];
  persona: Persona;
  marcasNeumatico?: Maybe<Array<MarcaNeumatico>>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type EstadoExpediente = {
  __typename?: "EstadoExpediente";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  descripcion?: Maybe<Scalars["String"]>;
  esEstadoInicial: Scalars["Boolean"];
  esEstadoFinal: Scalars["Boolean"];
};

export type ExistenteRelacion = {
  connect: Scalars["ID"];
};

export type Expediente = {
  __typename?: "Expediente";
  id: Scalars["ID"];
  nroExpediente?: Maybe<Scalars["String"]>;
  municipio: Municipio;
  tipoServicio: TipoServicio;
  empresaGeneradora?: Maybe<EmpresaGeneradora>;
  fechaNotificacion?: Maybe<Scalars["Date"]>;
  cantDiasAntesDeDispParaRetirar?: Maybe<Scalars["Int"]>;
  expedienteInicial?: Maybe<Expediente>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type ExpedientePreeliminar = {
  __typename?: "ExpedientePreeliminar";
  id?: Maybe<Scalars["ID"]>;
  total?: Maybe<Scalars["Float"]>;
  vencimiento?: Maybe<Scalars["Date"]>;
  detalles?: Maybe<Array<Maybe<DetalleLiquidacionExp>>>;
  detallesTotales?: Maybe<Array<Maybe<DetalleTotalExp>>>;
  empresa?: Maybe<EmpresaExp>;
  cat?: Maybe<CatExp>;
  municipio?: Maybe<MunicipioExp>;
};

export type ExpedienteReporte = {
  __typename?: "ExpedienteReporte";
  id: Scalars["ID"];
  nroExpediente?: Maybe<Scalars["String"]>;
  municipio: Scalars["String"];
  fechaNotificacion?: Maybe<Scalars["Date"]>;
  fechaDisponibleRetiro?: Maybe<Scalars["DateTime"]>;
  total: Scalars["Float"];
  fechaCreacion: Scalars["DateTime"];
  estado: Scalars["String"];
  empresaGeneradora: Scalars["String"];
  usuarioUltimaModificacion: Scalars["String"];
  fechaUltimaModificacion: Scalars["DateTime"];
  urlPrimeraNotificacion: Scalars["String"];
  vencimientoPrimeraNotificacion: Scalars["DateTime"];
};

export type Filtros = {
  Y?: Maybe<Array<Filtros>>;
  O?: Maybe<Array<Filtros>>;
  campo?: Maybe<Scalars["String"]>;
  operador?: Maybe<Operador>;
  valor?: Maybe<Scalars["Mixed"]>;
};

export type GestionExpediente = {
  __typename?: "GestionExpediente";
  id: Scalars["ID"];
  descripcion: Scalars["String"];
  tipoGestion: TipoGestion;
  autor: User;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type Historico = {
  __typename?: "Historico";
  nombre: Scalars["String"];
  datos?: Maybe<Scalars["String"]>;
  fechaCreacion: Scalars["DateTime"];
};

export type IngresarNfUsInput = {
  idDeclarante: Scalars["ID"];
  idEmpresa?: Maybe<Scalars["ID"]>;
  idTipoReciclador: Scalars["ID"];
  observaciones?: Maybe<Scalars["String"]>;
  firma?: Maybe<Scalars["String"]>;
  NFUs: Array<NfuInput>;
};

export type Item = {
  __typename?: "Item";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  descripcion?: Maybe<Scalars["String"]>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  itemPadre?: Maybe<Item>;
  itemHijos?: Maybe<Array<Maybe<Item>>>;
  permisos?: Maybe<Array<Maybe<Permiso>>>;
};

export type LoginInput = {
  username: Scalars["String"];
  password: Scalars["String"];
};

export type LogoutResponse = {
  __typename?: "LogoutResponse";
  status: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type MarcaNeumatico = {
  __typename?: "MarcaNeumatico";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  empresaGeneradora: EmpresaGeneradora;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type MarcaNeumaticoEmpresaGeneradoraArgs = {
  trashed?: Maybe<Trashed>;
};

export type MoverEstadoExpedienteInput = {
  idExpediente: Scalars["ID"];
  idEstadoNuevo: Scalars["ID"];
  detalle?: Maybe<Scalars["Json"]>;
  observaciones?: Maybe<Scalars["String"]>;
};

export type MovimientoExpediente = {
  __typename?: "MovimientoExpediente";
  id: Scalars["ID"];
  detalle?: Maybe<Scalars["Json"]>;
  observaciones?: Maybe<Scalars["String"]>;
  fechaEstado: Scalars["DateTime"];
  estado: Scalars["String"];
  estadoAnterior?: Maybe<Scalars["String"]>;
  responsable: Scalars["String"];
};

export type Municipio = {
  __typename?: "Municipio";
  id: Scalars["ID"];
  cat: Cat;
  ordenanza: Scalars["String"];
  ordenanzaUrl?: Maybe<Scalars["String"]>;
  emailNotificacion?: Maybe<Scalars["Email"]>;
  diasVencimientoLiquidacion: Scalars["Int"];
  persona: Persona;
  tasasDeTipoNeumatico?: Maybe<Array<Maybe<TasaDeTipoNeumatico>>>;
  tipoLocalidad: TipoLocalidad;
  firmante: Scalars["String"];
  medioDePago?: Maybe<Scalars["String"]>;
  cantDiasAntesDeDispParaRetirar?: Maybe<Scalars["Int"]>;
  firmaEncargado?: Maybe<Scalars["String"]>;
  mostrarFirma: Scalars["Boolean"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type MunicipioTipoLocalidadArgs = {
  trashed?: Maybe<Trashed>;
};

export type MunicipioExp = {
  __typename?: "MunicipioExp";
  id?: Maybe<Scalars["ID"]>;
  nombre?: Maybe<Scalars["String"]>;
  calle?: Maybe<Scalars["String"]>;
  cp?: Maybe<Scalars["Int"]>;
  ciudad?: Maybe<Scalars["String"]>;
  provincia?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  ordenanza?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Email"]>;
  datosDePagoPorTransferencia?: Maybe<Scalars["String"]>;
  cantDiasAntesDeDispParaRetirar?: Maybe<Scalars["Int"]>;
  tipo_localidad?: Maybe<Scalars["ID"]>;
  firmante?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  crearVariableGlobal?: Maybe<VariableGlobal>;
  actualizarVariableGlobal?: Maybe<VariableGlobal>;
  ingresar: AuthPayload;
  refrescarToken: AuthPayload;
  salir: LogoutResponse;
  crearUser?: Maybe<User>;
  borrarUser?: Maybe<User>;
  actualizarUser?: Maybe<User>;
  crearRol?: Maybe<Rol>;
  borrarRol?: Maybe<Rol>;
  actualizarRol?: Maybe<Rol>;
  crearPermiso?: Maybe<Permiso>;
  borrarPermiso?: Maybe<Permiso>;
  crearItem?: Maybe<Item>;
  borrarItem?: Maybe<Item>;
  actualizarItem?: Maybe<Item>;
  crearPersona?: Maybe<Persona>;
  borrarPersona?: Maybe<Persona>;
  actualizarPersona?: Maybe<Persona>;
  crearCat?: Maybe<Cat>;
  borrarCat?: Maybe<Cat>;
  actualizarCat?: Maybe<Cat>;
  ingresarNFUs: Comprobante;
  egresarNFUs: Comprobante;
  destruirNFUs: Comprobante;
  eliminarComprobante?: Maybe<Comprobante>;
  crearEmpresaGeneradora?: Maybe<EmpresaGeneradora>;
  borrarEmpresaGeneradora?: Maybe<EmpresaGeneradora>;
  actualizarEmpresaGeneradora?: Maybe<EmpresaGeneradora>;
  crearEmpresa?: Maybe<Empresa>;
  actualizarEmpresa?: Maybe<Empresa>;
  moverEstadoExpediente?: Maybe<MovimientoExpediente>;
  eliminarExpediente?: Maybe<Expediente>;
  generoExpedienteTipoNFUs: Array<Maybe<Expediente>>;
  cambiarFechaNotificacionExpediente: Expediente;
  crearGestionExpediente?: Maybe<GestionExpediente>;
};

export type MutationCrearVariableGlobalArgs = {
  data: CrearVariableGlobalInput;
};

export type MutationActualizarVariableGlobalArgs = {
  data: ActualizarVariableGlobalInput;
};

export type MutationIngresarArgs = {
  data: LoginInput;
};

export type MutationRefrescarTokenArgs = {
  data?: Maybe<RefreshTokenInput>;
};

export type MutationCrearUserArgs = {
  data: CrearUserInput;
};

export type MutationBorrarUserArgs = {
  id: Scalars["ID"];
};

export type MutationActualizarUserArgs = {
  data: ActualizarUserInput;
};

export type MutationCrearRolArgs = {
  data: CrearRolInput;
};

export type MutationBorrarRolArgs = {
  id: Scalars["ID"];
};

export type MutationActualizarRolArgs = {
  data: ActualizarRolInput;
};

export type MutationCrearPermisoArgs = {
  data: CrearPermisoInput;
};

export type MutationBorrarPermisoArgs = {
  id: Scalars["ID"];
};

export type MutationCrearItemArgs = {
  data: CrearItemInput;
};

export type MutationBorrarItemArgs = {
  id: Scalars["ID"];
};

export type MutationActualizarItemArgs = {
  data: ActualizarItemInput;
};

export type MutationCrearPersonaArgs = {
  data: CrearPersonaInput;
};

export type MutationBorrarPersonaArgs = {
  id: Scalars["ID"];
};

export type MutationActualizarPersonaArgs = {
  data: ActualizarPersonaInput;
};

export type MutationCrearCatArgs = {
  data: CrearCatInput;
};

export type MutationBorrarCatArgs = {
  id: Scalars["ID"];
};

export type MutationActualizarCatArgs = {
  data: ActualizarCatInput;
};

export type MutationIngresarNfUsArgs = {
  idCat: Scalars["ID"];
  data: IngresarNfUsInput;
};

export type MutationEgresarNfUsArgs = {
  idCat: Scalars["ID"];
  data: EgresarNfUsInput;
};

export type MutationDestruirNfUsArgs = {
  idCat: Scalars["ID"];
  data: DestruirNfUsInput;
};

export type MutationEliminarComprobanteArgs = {
  data: EliminarComprobanteInput;
};

export type MutationCrearEmpresaGeneradoraArgs = {
  data: CrearEmpresaGeneradoraInput;
};

export type MutationBorrarEmpresaGeneradoraArgs = {
  id: Scalars["ID"];
};

export type MutationActualizarEmpresaGeneradoraArgs = {
  data: ActualizarEmpresaGeneradoraInput;
};

export type MutationCrearEmpresaArgs = {
  data: CrearEmpresaInput;
};

export type MutationActualizarEmpresaArgs = {
  data: ActualizarEmpresaInput;
};

export type MutationMoverEstadoExpedienteArgs = {
  data: MoverEstadoExpedienteInput;
};

export type MutationEliminarExpedienteArgs = {
  data: EliminarExpedienteInput;
};

export type MutationGeneroExpedienteTipoNfUsArgs = {
  fechaHasta: Scalars["Date"];
  idsMunicipios: Array<Scalars["ID"]>;
  idsMarcas: Array<Scalars["ID"]>;
};

export type MutationCambiarFechaNotificacionExpedienteArgs = {
  data: CambiarFechaNotificacionExpedienteInput;
};

export type MutationCrearGestionExpedienteArgs = {
  data: CrearGestionExpedienteInput;
};

export type NfuInput = {
  idMarca: Scalars["ID"];
  idTipoNeumatico: Scalars["ID"];
  cantidad: Scalars["Int"];
};

export enum Operador {
  Igual = "IGUAL",
  Distinto = "DISTINTO",
  Mayor = "MAYOR",
  Menor = "MENOR",
  MenorIgual = "MENOR_IGUAL",
  MayorIgual = "MAYOR_IGUAL",
  Like = "LIKE",
  NotLike = "NOT_LIKE",
}

export type OrdenPor = {
  campo: Scalars["String"];
  orden?: Maybe<TipoOrden>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars["String"];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars["Int"]>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars["Int"]>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars["Int"]>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars["Int"]>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: "PaginatorInfo";
  /** Total count of available items in the page. */
  count: Scalars["Int"];
  /** Current pagination page. */
  currentPage: Scalars["Int"];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars["Int"]>;
  /** If collection has more pages. */
  hasMorePages: Scalars["Boolean"];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars["Int"]>;
  /** Last page number of the collection. */
  lastPage: Scalars["Int"];
  /** Number of items per page in the collection. */
  perPage: Scalars["Int"];
  /** Total items available in the collection. */
  total: Scalars["Int"];
};

export type Permiso = {
  __typename?: "Permiso";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  descripcion: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
};

export type Persona = {
  __typename?: "Persona";
  id: Scalars["ID"];
  nroDocumento: Scalars["String"];
  nombre: Scalars["String"];
  apellido?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Email"]>;
  telefono?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  firma?: Maybe<Scalars["String"]>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
  tipoPersona: TipoPersona;
  tipoDocumento: TipoDocumento;
  domicilio?: Maybe<Domicilio>;
};

export type PersonaTipoPersonaArgs = {
  trashed?: Maybe<Trashed>;
};

export type PersonaTipoDocumentoArgs = {
  trashed?: Maybe<Trashed>;
};

export type Provincia = {
  __typename?: "Provincia";
  id: Scalars["ID"];
  codigo31662: Scalars["String"];
  nombre: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  ciudades?: Maybe<Array<Maybe<Ciudad>>>;
};

export type Query = {
  __typename?: "Query";
  variablesGlobales: Array<Maybe<VariableGlobal>>;
  users: Array<Maybe<User>>;
  yo: User;
  roles: Array<Maybe<Rol>>;
  permisos: Array<Maybe<Permiso>>;
  items: Array<Maybe<Item>>;
  provincias: Array<Maybe<Provincia>>;
  ciudades: Array<Maybe<Ciudad>>;
  persona?: Maybe<Persona>;
  personas: Array<Maybe<Persona>>;
  tiposDocumento: Array<Maybe<TipoDocumento>>;
  municipios: Array<Maybe<Municipio>>;
  tasasDeTipoNeumatico: Array<Maybe<TasaDeTipoNeumatico>>;
  tiposLocalidad: Array<Maybe<TipoLocalidad>>;
  cats: Array<Maybe<Cat>>;
  stockNFUs: Array<Maybe<StockNfUs>>;
  stockNFUsDestruidosEnCat: Array<Maybe<StockNfUsDestruidosEnCat>>;
  stockNFUsParaEgreso: Scalars["Int"];
  stockNFUsParaDestruccion: Scalars["Int"];
  tiposNeumatico: Array<Maybe<TipoNeumatico>>;
  marcasNeumatico: Array<Maybe<MarcaNeumatico>>;
  comprobantes: Array<Maybe<Comprobante>>;
  empresasGeneradoras: Array<Maybe<EmpresaGeneradora>>;
  empresa?: Maybe<Empresa>;
  expedientesNFUs?: Maybe<Array<Maybe<ExpedienteReporte>>>;
  estadosPosibleDeEstadoActual: Array<Maybe<EstadoExpediente>>;
  estadosExpedientes: Array<Maybe<EstadoExpediente>>;
  movimientosExpediente: Array<Maybe<MovimientoExpediente>>;
  preliminarNuevosExpedientesNFUs: Array<Maybe<ExpedientePreeliminar>>;
  gestionesPorExpediente: Array<Maybe<GestionExpediente>>;
  tiposGestion: Array<Maybe<TipoGestion>>;
  recaudacionMensualPorMunicipio?: Maybe<Array<Maybe<DatosMultipleGrafico>>>;
  cantidadNFUsEnCatHistorico?: Maybe<Array<Maybe<DatosMultipleGrafico>>>;
  cantidadNFUsPorTipoYMunicipio?: Maybe<Array<Maybe<DatosMultipleGrafico>>>;
  cantidadNFUsPorTipoYCat?: Maybe<Array<Maybe<DatosMultipleGrafico>>>;
};

export type QueryUsersArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryRolesArgs = {
  filtros?: Maybe<Filtros>;
  borrados?: Maybe<Trashed>;
};

export type QueryPermisosArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryProvinciasArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryCiudadesArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryPersonaArgs = {
  tipoDocumento: Scalars["ID"];
  nroDocumento: Scalars["String"];
};

export type QueryPersonasArgs = {
  filtros?: Maybe<Filtros>;
  trashed?: Maybe<Trashed>;
};

export type QueryTiposDocumentoArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryMunicipiosArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryTasasDeTipoNeumaticoArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryTiposLocalidadArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryStockNfUsArgs = {
  filtros?: Maybe<StockNfUsInput>;
};

export type QueryStockNfUsDestruidosEnCatArgs = {
  filtros?: Maybe<StockNfUsDestruidosEnCatInput>;
};

export type QueryStockNfUsParaEgresoArgs = {
  filtros: StockNfUsEgresoInput;
};

export type QueryStockNfUsParaDestruccionArgs = {
  filtros: StockNfUsDestruccionInput;
};

export type QueryTiposNeumaticoArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryMarcasNeumaticoArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryComprobantesArgs = {
  filtros?: Maybe<Filtros>;
  trashed?: Maybe<Trashed>;
};

export type QueryEmpresasGeneradorasArgs = {
  filtros?: Maybe<Filtros>;
};

export type QueryEmpresaArgs = {
  tipoDocumento: Scalars["ID"];
  nroDocumento: Scalars["String"];
};

export type QueryExpedientesNfUsArgs = {
  idCat?: Maybe<Scalars["ID"]>;
};

export type QueryEstadosPosibleDeEstadoActualArgs = {
  idExpediente: Scalars["ID"];
};

export type QueryEstadosExpedientesArgs = {
  idTipoServicio: Scalars["ID"];
};

export type QueryMovimientosExpedienteArgs = {
  idExpediente: Scalars["ID"];
};

export type QueryPreliminarNuevosExpedientesNfUsArgs = {
  fechaHasta: Scalars["Date"];
  idsMunicipios: Array<Scalars["ID"]>;
  idsMarcas: Array<Scalars["ID"]>;
};

export type QueryGestionesPorExpedienteArgs = {
  expediente_id: Scalars["ID"];
};

export type QueryTiposGestionArgs = {
  filtros?: Maybe<Filtros>;
};

export type RefreshTokenInput = {
  refresh_token?: Maybe<Scalars["String"]>;
};

export type RegisterResponse = {
  __typename?: "RegisterResponse";
  status: Scalars["String"];
  message: Scalars["String"];
};

export type Rol = {
  __typename?: "Rol";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  descripcion: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  permisos?: Maybe<Array<Maybe<Permiso>>>;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = "ASC",
  /** Sort records in descending order. */
  Desc = "DESC",
}

export type StockNfUs = {
  __typename?: "StockNFUs";
  municipio: Scalars["String"];
  cat: Scalars["String"];
  empresa: Scalars["String"];
  marcaNeumatico: Scalars["String"];
  tipoNeumatico: Scalars["String"];
  estado?: Maybe<Scalars["String"]>;
  cantidad: Scalars["Int"];
  cantidadLiberada: Scalars["Int"];
};

export type StockNfUsDestruccionInput = {
  idCat: Scalars["ID"];
  idTipoNeumatico: Scalars["ID"];
  idMarca: Scalars["ID"];
};

export type StockNfUsDestruidosEnCat = {
  __typename?: "StockNFUsDestruidosEnCat";
  idMunicipio: Scalars["Int"];
  municipio: Scalars["String"];
  idCat: Scalars["Int"];
  cat: Scalars["String"];
  kgsDestruidos: Scalars["Float"];
};

export type StockNfUsDestruidosEnCatInput = {
  idMunicipio?: Maybe<Scalars["ID"]>;
  idCat?: Maybe<Scalars["ID"]>;
};

export type StockNfUsEgresoInput = {
  idCat: Scalars["ID"];
  idTipoNeumatico: Scalars["ID"];
  idMarca: Scalars["ID"];
};

export type StockNfUsInput = {
  idMunicipio?: Maybe<Scalars["ID"]>;
  idCat?: Maybe<Scalars["ID"]>;
  idEmpresaGeneradora?: Maybe<Scalars["ID"]>;
  idTipoNeumatico?: Maybe<Scalars["ID"]>;
  idMarcaNeumatico?: Maybe<Scalars["ID"]>;
  idEstadoComprobante?: Maybe<Scalars["ID"]>;
  idTipoComprobante?: Maybe<Scalars["ID"]>;
  fecha?: Maybe<Scalars["DateTime"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  personaActualizada?: Maybe<Persona>;
  nuevoIngresoNFUs?: Maybe<Comprobante>;
  nuevoEgresoNFUs?: Maybe<Comprobante>;
  nuevaDestruccionNFUs?: Maybe<Comprobante>;
  eliminarExpediente?: Maybe<Expediente>;
};

export type TasaDeTipoNeumatico = {
  __typename?: "TasaDeTipoNeumatico";
  id: Scalars["ID"];
  tasaUnitaria: Scalars["Float"];
  municipio: Municipio;
  tipoNeumatico: TipoNeumatico;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoComprobante = {
  __typename?: "TipoComprobante";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  descripcion?: Maybe<Scalars["String"]>;
  comprobantes?: Maybe<Array<Maybe<Comprobante>>>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoDocumento = {
  __typename?: "TipoDocumento";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoDomicilio = {
  __typename?: "TipoDomicilio";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
};

export type TipoGestion = {
  __typename?: "TipoGestion";
  id: Scalars["ID"];
  descripcion: Scalars["String"];
  tipoServicio: TipoServicio;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoLocalidad = {
  __typename?: "TipoLocalidad";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoNeumatico = {
  __typename?: "TipoNeumatico";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  peso: Scalars["String"];
  descripcion: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export enum TipoOrden {
  Asc = "ASC",
  Desc = "DESC",
}

export type TipoPersona = {
  __typename?: "TipoPersona";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoReciclador = {
  __typename?: "TipoReciclador";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  comprobantes?: Maybe<Array<Maybe<Comprobante>>>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};

export type TipoServicio = {
  __typename?: "TipoServicio";
  id: Scalars["ID"];
  abreviatura: Scalars["String"];
  descripcion: Scalars["String"];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = "ONLY",
  /** Return both trashed and non-trashed results. */
  With = "WITH",
  /** Only return non-trashed results. */
  Without = "WITHOUT",
}

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  name: Scalars["String"];
  username: Scalars["String"];
  email?: Maybe<Scalars["Email"]>;
  superAdmin: Scalars["Boolean"];
  ultimaConexion?: Maybe<Scalars["DateTime"]>;
  ipUltimaConexion?: Maybe<Scalars["String"]>;
  accessTokens?: Maybe<Array<Maybe<AccessToken>>>;
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  persona?: Maybe<Persona>;
  roles?: Maybe<Array<Maybe<Rol>>>;
  cats?: Maybe<Array<Maybe<Cat>>>;
  permisos?: Maybe<Array<Maybe<Permiso>>>;
};

export type VariableGlobal = {
  __typename?: "VariableGlobal";
  id: Scalars["ID"];
  nombre: Scalars["String"];
  valor: Scalars["String"];
  descripcion: Scalars["String"];
  fechaCreacion: Scalars["DateTime"];
  fechaActualizacion: Scalars["DateTime"];
  fechaBaja?: Maybe<Scalars["DateTime"]>;
};
