import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationCambiarFechaNotificacionExpedienteArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class CambiarFechaNotificacionExpedienteGQL extends Mutation<
  TypeMutation,
  MutationCambiarFechaNotificacionExpedienteArgs
> {
  document = gql`
    mutation cambiarFechaNotificacionExpediente(
      $data: CambiarFechaNotificacionExpedienteInput!
    ) {
      cambiarFechaNotificacionExpediente(data: $data) {
        id
        nroExpediente
        fechaNotificacion
      }
    }
  `;
}
