import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationBorrarEmpresaGeneradoraArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarEmpresaGeneradoraGQL extends Mutation<
  TypeMutation,
  MutationBorrarEmpresaGeneradoraArgs
> {
  document = gql`
    mutation borrarEmpresaGeneradora($id: ID!) {
      borrarEmpresaGeneradora(id: $id) {
        id
        notifica
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            piso
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        marcasNeumatico {
          id
          nombre
        }
      }
    }
  `;
}
