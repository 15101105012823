import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryComprobantesArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class ComprobantesGQL extends Query<TypeQuery, QueryComprobantesArgs> {
  document = gql`
    query comprobantes($filtros: Filtros) {
      comprobantes(filtros: $filtros) {
        id
        fecha
        ubicacionFisica
        persona {
          id
          nombre
          apellido
        }
        tipoComprobante {
          id
          nombre
        }
        empresa {
          id
          persona {
            id
            nombre
          }
        }
        observaciones
        fechaCreacion
        fechaActualizacion
      }
    }
  `;
}
