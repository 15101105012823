import { Injectable } from "@angular/core";
import { User } from "src/app/graphql/Graphql";
import { UsersGQL } from "src/app/graphql/queries/usersGQL";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UsuariosService {
  constructor(private usuariosGQL: UsersGQL) {}

  getUsuarios(): Observable<User[]> {
    return this.usuariosGQL.watch().valueChanges.pipe(
      map(({ data }) => data?.users),
      catchError((err) => throwError(err))
    );
  }
}
