import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationActualizarCatArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class ActualizarCatGQL extends Mutation<
  TypeMutation,
  MutationActualizarCatArgs
> {
  document = gql`
    mutation actualizarCat($data: ActualizarCatInput!) {
      actualizarCat(data: $data) {
        id
        horariosAtencion
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        municipio {
          id
          ordenanza
          ordenanzaUrl
          emailNotificacion
          diasVencimientoLiquidacion
          persona {
            id
            nombre
            nroDocumento
            email
            telefono
            logo
            domicilio {
              id
              direccion
              numero
              piso
              lat
              lng
            }
          }
          tasasDeTipoNeumatico {
            id
            tasaUnitaria
            tipoNeumatico {
              id
            }
          }
          medioDePago
          firmante
          tipoLocalidad{
            id
            nombre
          }          
        }
      }
    }
  `;
}
