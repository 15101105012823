import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { map, combineLatest, switchMap, take } from "rxjs/operators";
import { Permiso, Rol } from "src/app/graphql/Graphql";
import {
  ActualizarRolGQL,
  CrearPermisoGQL,
  CrearRolGQL,
  EliminarPermisoGQL,
  EliminarRolGQL,
} from "src/app/graphql/mutation.index";
import { RolesGQL, PermisosGQL } from "src/app/graphql/query.index";
import { AuthService } from "./auth.service";
import { ItemsService } from "./items.service";

@Injectable({
  providedIn: "root",
})
export class PermisosService {
  constructor(
    private authService: AuthService,
    private rolesGQL: RolesGQL,
    private crearRolGQL: CrearRolGQL,
    private actualizarRolGQl: ActualizarRolGQL,
    private eliminarRolGQL: EliminarRolGQL,
    private permisosGQL: PermisosGQL,
    private crearPermisoGQL: CrearPermisoGQL,
    private eliminarPermisoGQL: EliminarPermisoGQL,
    private itemsService: ItemsService
  ) {}

  public esAdmin(): Observable<boolean> {
    return this.authService
      .getUsuarioActual()
      .pipe(map((user) => user?.superAdmin));
  }

  public tieneAlgunPermiso(permisosAValidar: string[]): Observable<boolean> {
    return this.esAdmin().pipe(
      take(1),
      switchMap((esAdmin) =>
        this.authService.getUsuarioActual().pipe(
          take(1),
          map(
            (user) =>
              esAdmin ||
              (user && user.permisos
                ? user?.permisos.filter((p) =>
                    permisosAValidar.includes(p.nombre)
                  ).length > 0
                : false) ||
              (user && user.roles
                ? user.roles.filter((r) =>
                    r.permisos
                      ? r.permisos.filter((p) =>
                          permisosAValidar.includes(p.nombre)
                        ).length > 0
                      : false
                  ).length > 0
                : false)
          )
        )
      )
    );
  }

  tienePermisoEnLaRuta(path: string): Observable<boolean> {
    return this.esAdmin().pipe(
      switchMap((esAdmin) =>
        this.itemsService
          .getItems()
          .pipe(
            map(
              (items) =>
                esAdmin ||
                (items
                  ? items.filter((i) =>
                      i.itemHijos
                        ? i.itemHijos.filter((ih) => ih.link === path).length >
                          0
                        : false
                    ).length > 0
                  : false)
            )
          )
      )
    );
  }

  getRoles(): Observable<Rol[]> {
    return this.rolesGQL
      .watch()
      .valueChanges.pipe(map(({ data }) => data?.roles));
  }

  createRol(nomRol: string, descRol: string, p: Permiso[]): Observable<Rol> {
    let data = {
      nombre: nomRol,
      descripcion: descRol,
      permisos: {
        sync: p.map((per) => per.id),
      },
    };

    return this.crearRolGQL
      .mutate({
        data: data,
      })
      .pipe(map(({ data }) => data?.crearRol));
  }

  actualizarRol(idRol, nomRol, descRol, p: Permiso[]): Observable<Rol> {
    let data = {
      id: idRol,
      nombre: nomRol,
      descripcion: descRol,
      permisos: {
        sync: p.map((per) => per.id),
      },
    };

    return this.actualizarRolGQl
      .mutate({
        data: data,
      })
      .pipe(map(({ data }) => data?.actualizarRol));
  }

  deleteRol(idRol): Observable<Rol> {
    return this.eliminarRolGQL
      .mutate({
        id: idRol,
      })
      .pipe(map(({ data }) => data?.borrarRol));
  }

  getPermisos(): Observable<Permiso[]> {
    return this.permisosGQL
      .watch()
      .valueChanges.pipe(map(({ data }) => data?.permisos));
  }

  createPermiso(nomPer: string, descPer: string): Observable<Permiso> {
    return this.crearPermisoGQL
      .mutate({
        data: {
          nombre: nomPer,
          descripcion: descPer,
        },
      })
      .pipe(map(({ data }) => data?.crearPermiso));
  }

  deletePermiso(idPermiso: string): Observable<Permiso> {
    return this.eliminarPermisoGQL
      .mutate({
        id: idPermiso,
      })
      .pipe(map(({ data }) => data?.borrarPermiso));
  }
}
