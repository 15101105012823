import { AuthService } from "src/app/services/service.index";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "@angular/cdk/layout";

// Rutas
import { AppRoutingModule } from "./app-routing.module";

// Modulos
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgxPermissionsModule,
  NgxPermissionsService,
  NgxRolesService,
} from "ngx-permissions";

// Servicios
import { ServiceModule } from "./services/service.module";

// Componenetes
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { GraphQLModule } from "./graphql.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NoCacheHeadersInterceptor } from "./no-cache-headers-interceptor";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { PaginationModule } from "ngx-bootstrap/pagination";

//Pipes
import { ItemnsMenuPipe } from "./pipes/itemns-menu.pipe";
import { TipoComprobantePipe } from "./pipes/tipo-comprobante.pipe";
import { ChartsModule } from "ng2-charts";
import { GlobalErrorHandler } from "./global-error-handler";
import { ModalModule } from "ngx-bootstrap/modal";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

export function loadPermisos(authService: AuthService) {
  return () => authService.loadUser();
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ServiceModule,
    CommonModule,
    LayoutModule,
    GraphQLModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    PaginationModule.forRoot(),
    ChartsModule,
    NgxPermissionsModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  declarations: [AppComponent, ItemnsMenuPipe, TipoComprobantePipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    //{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
    {
      provide: APP_INITIALIZER,
      useFactory: loadPermisos,
      deps: [AuthService, NgxPermissionsService, NgxRolesService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
