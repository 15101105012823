import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryUsersArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class UsersGQL extends Query<TypeQuery, QueryUsersArgs> {
  document = gql`
    query users($filtros: Filtros) {
      users(filtros: $filtros) {
        id
        name
        username
        superAdmin
        email
        ultimaConexion
        ipUltimaConexion
        roles {
          id
          nombre
        }
        cats {
          id
          persona {
            id
            nombre
            domicilio {
              id
              direccion
            }
          }
          municipio {
            id
            persona {
              id
              nombre
            }
          }
        }
        persona {
          id
          nombre
          apellido
          telefono
          email
          nroDocumento
          firma
          tipoDocumento {
            id
            nombre
          }

          domicilio {
            id
            direccion
            numero
            piso
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        accessTokens {
          id
          fechaAccesso
        }
      }
    }
  `;
}
